import React, { PropsWithChildren } from 'react'


type TitleProps = PropsWithChildren<{
  className?: string,
  marginTop?: string,
}>

const Title : React.FC<TitleProps> = ({marginTop, children, className}) => {
  return (
    <div className={`
      font-ofelia uppercase text-2xl non-italic
      ${marginTop ?? "mt-8"}
      ${className}
      `}>
      {children}
    </div>
  )
}

export default Title