import { useEffect, useState } from "react"

const useStoryFoldStates = (stories: StoryToCreate[]) => {

  const [storyFoldStates, setStoryFoldStates] = useState<{ id: number, foldState: boolean }[]>()

  // Initialize fold states
  useEffect(() => {    
    setStoryFoldStates(stories.map((story) => {
      return { id: story.id, foldState: false }
    }))
  }, [stories]
  )

  const onModuleFoldStateChanged = (id: number, unfold: boolean) => {
    if (storyFoldStates === undefined) return

    if (unfold) {
      let newState = storyFoldStates.map((foldState) => {
        return { id: foldState.id, foldState: foldState.id === id }
      })
      setStoryFoldStates(newState)
    }
    else {
      let newState = storyFoldStates.map((foldState) => {
        return { id: foldState.id, foldState: foldState.id !== id ? foldState.foldState : false }
      })
      setStoryFoldStates(newState)
    }
  }

  return {
    storyFoldStates: storyFoldStates,
    onModuleFoldStateChanged: onModuleFoldStateChanged,
  }
}

export default useStoryFoldStates
