import RaumBackgroundImage from '../static/image/Raum-low.jpg'
import ContentModule from '../components/ContentModule'
import Container from '../components/Container'
import usePageText from '../hooks/usePageText'
import useStories from '../hooks/useStories'
import useRandom from '../hooks/useRandom'
import Loader from '../components/Loader'
import useStoryFoldStates from '../hooks/useStoryFoldStates'

import RaumStatic1 from '../static/image/Raum-01-low.jpeg'
import RaumStatic2 from '../static/image/Raum-02-low.jpeg'
import Header from '../components/Header'
import useWindowResize from '../hooks/useWindowResize'

const Raum = () => {
  const { pageText, loading: pageTextLoading } = usePageText("ROOM")
  const { stories, loading: storiesLoading } = useStories("ROOM")
  const { storyFoldStates, onModuleFoldStateChanged } = useStoryFoldStates(stories)

  const { seed } = useRandom(5)

  const { isMobile } = useWindowResize()

  return (
    <div>
      <Header bubbleText='Raum' BgImage={RaumBackgroundImage} />

      {
        // Page Text
        <Container>
          <div className={`${isMobile ? "mt-6" : "mt-16"}`}  dangerouslySetInnerHTML={{ __html: pageText }} />
        </Container>
      }

      {
        // STORIES
        <Container className='mt-6' paddingMobile={false}>
          {
            stories.map((story) => {
              return (
                <ContentModule
                  foldState={storyFoldStates?.find(s => s.id === story.id)?.foldState}
                  onFoldStateChanged={(unfold) => onModuleFoldStateChanged(story.id, unfold)}
                  story={story} />
              )
            })
          }
        </Container>
      }

      {
        // Loader
        (pageTextLoading || storiesLoading) && <Loader seed={seed} />
      }

      {
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Raum 1" src={RaumStatic1} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }

      {
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Raum 2" src={RaumStatic2} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }
    </div>
  )
}

export default Raum