import MemberList from "./members/MemberList";
import {ProtectedRoute} from "../../ProtectedRoute";
import EnvPage from "./EnvPage";
import React from "react";
import PageTexts from "./pageTexts/PageTexts";
import StoryList from "./stories/StoryList";
import StoryCreate from "./stories/StoryCreate";

const routes = [
    {
        path: "",
        element: (
            <ProtectedRoute>
                <MemberList/>
            </ProtectedRoute>
        )
    },
    {
        path: "env",
        element: (
            <ProtectedRoute>
                <EnvPage/>
            </ProtectedRoute>
        ),
    },
    {
        path: "members",
        element: (
            <ProtectedRoute>
                <MemberList/>
            </ProtectedRoute>
        )
    },
    {
        path: "stories",
        element: (
            <ProtectedRoute>
                <StoryList/>
            </ProtectedRoute>
        )
    },
    {
        path: "stories/new",
        element: (
            <ProtectedRoute>
                <StoryCreate/>
            </ProtectedRoute>
        )
    },
    {
        path: "stories/:id",
        element: (
            <ProtectedRoute>
                <StoryCreate/>
            </ProtectedRoute>
        )
    },
    {
        path: "pageTexts",
        element: (
            <ProtectedRoute>
                <PageTexts/>
            </ProtectedRoute>
        )
    }
]

export default routes;