import { useEffect, useState } from "react"
import config from "../config.json"

const useStoryDetail = (id: string | undefined) => {
  const [story, setStory] = useState<StoryToCreate>()
  const [loading, setLoading] = useState(false)

  // Retrieve stories from API
  useEffect(() => {
    if(!id) return 
    
    setLoading(true)
    fetch(`${config.api.endpoint}/api/stories/${id}`).then((res) => {
      res.json().then((data) => {
        setStory(data.result)
        setLoading(false)
      })
    }).catch(() => {
      console.error("There was an error fetching stories.")
      setLoading(false)
    })
  }, [id])

  return {
    story,
    loading
  }
}

export default useStoryDetail