import React, {useState} from 'react';

type Props = {
    buttons: ButtonToCreate[];
    handleSave?: (buttons: ButtonToCreate[]) => void;
}

function ButtonsEditor({buttons: defaultButtons, handleSave}: Props) {

    const [buttons, setButtons] = useState<ButtonToCreate[]>(defaultButtons)
    const [isNew, setIsNew] = useState<boolean>(false);
    const [buttonEditing, setButtonEditing] = useState<ButtonToCreate | undefined>();
    const [buttonEditingIndex, setButtonEditingIndex] = useState<number>(-1);

    const onStartEdit = (button: ButtonToCreate, index: number, isNew: boolean) => {
        setIsNew(isNew);
        if (isNew) {
            setButtonEditing(button);
        } else {
            setButtonEditing(button);
            setButtonEditingIndex(index);
            buttons.splice(index, 1);
            setButtons(buttons);
        }
    }

    const onDelete = () => {
        if (isNew) {
            setButtonEditing(undefined);
            setButtonEditingIndex(-1);
        } else {
            if (window.confirm("Bestätigen, um es zu löschen?")) {
                setButtonEditing(undefined);
                setButtonEditingIndex(-1);
                if (handleSave) {
                    handleSave(buttons);
                }
            }
        }
    }

    const onSave = () => {
        if (buttonEditing) {
            if (isNew) {
                buttons.push(buttonEditing);
            } else {
                buttons.splice(buttonEditingIndex, 0, buttonEditing);
            }
            setButtons(buttons);
        }
        setButtonEditing(undefined);
        setButtonEditingIndex(-1);
        if (handleSave) {
            handleSave(buttons);
        }
    }

    return (
        <div>
            <label htmlFor="buttons" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Tasten
            </label>
            {
                buttons && buttons.map((button, index) => {
                    if (button.isExternal) {
                        return (
                            <button
                                key={index}
                                type="button"
                                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                                onClick={() => onStartEdit(button, index, false)}
                                disabled={Boolean(buttonEditing)}
                            >
                                {
                                    button.text
                                }
                            </button>
                        )
                    } else {
                        return (
                            <button
                                key={index}
                                type="button"
                                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                onClick={() => onStartEdit(button, index, false)}
                                disabled={Boolean(buttonEditing)}
                            >
                                {
                                    button.text
                                }
                            </button>
                        )
                    }
                })
            }

            {
                buttonEditing && (
                    <div className="max-w-sm mx-auto">
                        <div className="mb-2">
                            <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Schaltflächentext"
                                value={buttonEditing.text}
                                onChange={(e) => {
                                    setButtonEditing({
                                        ...buttonEditing,
                                        text: e.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className="mb-2">
                            <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Verknüpfung"
                                value={buttonEditing.link}
                                onChange={(e) => {
                                    setButtonEditing({
                                        ...buttonEditing,
                                        link: e.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className="mb-2">
                            <select
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={String(buttonEditing.isExternal)}
                                onChange={(e) => {
                                    setButtonEditing({
                                        ...buttonEditing,
                                        isExternal: Boolean(e.target.value)
                                    })
                                }}
                            >
                                <option value="false">intern</option>
                                <option value="true">extern</option>
                            </select>
                        </div>
                        <button
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={onSave}
                        >
                            Speichern
                        </button>
                        <button
                            className="ml-2 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                            onClick={onDelete}
                        >
                            {
                                isNew ? "Stornieren" : "Löschen"
                            }
                        </button>
                    </div>
                )
            }
            {
                !buttonEditing && (
                    <div className="max-w-sm mx-auto">
                        <button
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => onStartEdit({
                                text: "",
                                link: "",
                                isExternal: false
                            }, -1, true)}
                        >
                            Neue Schaltfläche
                        </button>
                    </div>
                )
            }

        </div>
    );
}

export default ButtonsEditor;
