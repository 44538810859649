import { forwardRef, useRef } from 'react'
import ForumLogoSmall from '../static/image/FORUM_logo.svg'
import useWindowResize from '../hooks/useWindowResize'
import { ReactComponent as CloseIcon } from '../static/image/Zeichen-1px_close.svg'
import { ReactComponent as MenuIcon } from '../static/image/Zeichen-1px_menu.svg'
import { Link } from 'react-router-dom'
import Nav from './Nav'

type HeadlineProps = {
  menuIsOpen?: boolean,
  onMenuIsOpenChanged?: (menuIsOpen: boolean) => void,
  handleLogoClick?: () => void,
  handleNavClose?: () => void,
}

const Headline = forwardRef<HTMLDivElement, HeadlineProps>(({ handleLogoClick, menuIsOpen, onMenuIsOpenChanged, handleNavClose }, ref) => {

  const { isMobile } = useWindowResize()

  const logoRef = useRef<HTMLImageElement>(null)

  type LogoImgProps = {
    className?: string,
    src: string,
  }

  const LogoImg: React.FC<LogoImgProps> = ({ className, src }) => {
    return (
      <Link onClick={handleLogoClick} to={"/"} state={{ logoClicked: true }}
        className={`
          ${className} 
          inline-block z-10 drop-shadow-[0_0_1rem_rgba(246,236,215,0.5)] min-w-[10rem]
          `}>
        <img id="logo"
          ref={logoRef}
          src={src}
          alt="Forum Logo"
          className='h-full object-contain'
        />
      </Link>
    )
  }

  const handleToggleMenu = () => {
    onMenuIsOpenChanged && onMenuIsOpenChanged(!menuIsOpen)
  }

  return (
    <div ref={ref} className='fixed top-0 bg-beige w-full z-50'>

      <div className={`max-w-6xl mx-auto text-center  ${(menuIsOpen && isMobile) ? "h-screen" : ""} `}>
        {
          /* Mobile Nav Toggle */
          isMobile &&
          <div className='absolute right-0 h-[5rem] w-16 flex items-end'>
            <button className={`flex items-center justify-center pb-3`} onClick={handleToggleMenu}>
              {
                menuIsOpen ?
                  <CloseIcon className='w-8 h-8 mx-4' />
                  : <MenuIcon className='w-8 h-8 mx-4' />
              }
            </button>
          </div>
        }


        <LogoImg className={`mx-auto text-center h-[5rem]`} src={ForumLogoSmall} />

        {
          /* Show Nav if is not mobile */
          !isMobile && <Nav className="" onClose={handleNavClose} />
        }

        {
          /* Show Nav if is mobile and menu is open */
          isMobile && menuIsOpen && (
            <div style={{ height: `calc(100vh - 5rem)` }} className='absolute top-[5rem] w-full bg-beige z-40'>
              <Nav onClose={handleNavClose} />
            </div>
          )
        }

      </div>
    </div>

  )
})

export default Headline