import BuecherBackgroundImage from '../static/image/Buecher-low.jpg'

import { ReactComponent as BuecherSvg } from "../components/Bubbles/Buecher.svg"
import ContentModule from '../components/ContentModule'
import Container from '../components/Container'
import Loader from '../components/Loader'
import useStories from '../hooks/useStories'
import usePageText from '../hooks/usePageText'
import useRandom from '../hooks/useRandom'
import useStoryFoldStates from "../hooks/useStoryFoldStates"

import BuecherStatic1 from '../static/image/Buecher-01-low.jpeg'
import BuecherStatic2 from '../static/image/Buecher-02-low.jpeg'
import useWindowResize from '../hooks/useWindowResize'
import Header from '../components/Header'

const Buecher = () => {

  const { stories, loading: storiesLoading } = useStories("BOOKS")
  const { pageText, loading: pageTextLoading } = usePageText("BOOKS")
  const { storyFoldStates, onModuleFoldStateChanged } = useStoryFoldStates(stories)

  const { seed } = useRandom(5)
  const { isMobile } = useWindowResize()

  return (
    <div>
      <Header Svg={BuecherSvg} BgImage={BuecherBackgroundImage} />

      {
        // Page Text
        <Container>
          <div className={`${isMobile ? "mt-6" : "mt-16"}`} dangerouslySetInnerHTML={{ __html: pageText }} />
        </Container>
      }

      {
        // STORIES
        <Container paddingMobile={false}>
          {
            stories.map((story) => {
              return (
                <ContentModule
                  foldState={storyFoldStates?.find(s => s.id === story.id)?.foldState}
                  onFoldStateChanged={(unfold) => onModuleFoldStateChanged(story.id, unfold)}
                  story={story} />
              )
            })
          }
        </Container>
      }

      {
        // Loader
        (pageTextLoading || storiesLoading) && <Loader seed={seed} />
        
      }


      {
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Bücher 1" src={BuecherStatic1} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16"}`} />
        </Container>
      }

      {
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Bücher 2" src={BuecherStatic2} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }



    </div>

  )
}

export default Buecher