
export const download = (blob: Blob, headers: Headers) => {

    const url = URL.createObjectURL((
        blob
    ))
    const fileName = headers.get("X-TB6-Filename") || "file.txt";

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

}