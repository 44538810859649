import { useEffect } from "react"

const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: any) => (scrollWidth > clientWidth) || (scrollHeight > clientHeight)

const resizeText: React.FC<any> = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) => {
  return (
    (elements || [element]).forEach((el: any) => {
      if (el !== null) {
        let i = minSize
        let overflow = false

        const parent = el.parentNode

        while (!overflow && i < maxSize) {
          el.style.fontSize = `${i}${unit}`
          overflow = isOverflown(parent)
          if (!overflow) i += step
        }

        // revert to last state where no overflow happened
        el.style.fontSize = `${i - step}${unit}`
      }
    })
  )
}

type BubbleProps = {
  Svg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  bgClassName?: string,
  svgClassName?: string,
  bubbleClassName?: string,
  text?: string,
}
const Bubble: React.FC<BubbleProps> = ({ text, Svg, bgClassName, svgClassName, bubbleClassName }) => {

  useEffect(() => {
    resizeText({ element: document.querySelector(`.bubbleText`), step: 0.5 })
  }, [])

  return (
    <div className={bubbleClassName}>
      {
        // Bubble Background
        <div className={bgClassName} />
      }

      {
        // Bubble Text
        Svg ? <Svg className={svgClassName} />
          :
          !Svg && text && <p className="absolute inset-0 m-6 flex justify-center items-center text-white font-ofelia uppercase">
            <span className="bubbleText">{text}</span>
          </p>
      }


    </div>
  )
}

export default Bubble