import MultiStatesButton from "./MultiStatesButton";

type Color = "blue" | "red" | "dark";

type Action = {
    normalText: string,
    loadingText?: string,
    action: () => void,
    loading: boolean,
    color: Color,
    enabled: boolean
}

type Props = {
    actions: Action[]
}

const TableActions = (props: Props) => {
    return (
        <div
            className="px-3 pt-3 space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
            {
                props.actions.map((action, index) => (
                    <MultiStatesButton
                        key={index}
                        color={action.color}
                        disabled={!action.enabled}
                        action={action.action}
                        normalText={action.normalText}
                        loadingText={action.loadingText || ""}
                        loading={action.loading}
                    />
                ))
            }
        </div>
    )
}

export default TableActions;