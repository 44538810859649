import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth";
import {deleteStories, fetchAllStories, sortStories} from "../../../services/APIService";
import ConfirmPopup from "../../../components/ConfirmPopup";
import TableActions from "../../../components/admin/TableActions";
import moment from "moment";
import PageLoader from "../../../components/admin/PageLoader";
import {toast} from "react-toastify";

import {convert} from 'html-to-text';


function StoryList() {

    const [stories, setStories] = useState<StoryToCreate[]>([]);
    const [loading, setLoading] = useState(true);

    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [checkStates, setCheckStates] = useState<boolean[]>(new Array(stories.length).fill(false));
    const [anyChecked, setAnyChecked] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showSortOrder, setShowSortOrder] = useState<boolean>(false);
    const [sorting, setSorting] = useState<boolean>(false);
    const [sortOrderMap, setSortOrderMap] = useState<{
        [key: number]: number
    }>({});

    const navigate = useNavigate();
    const authContext = useAuth();
    const serviceContext = useMemo(() => ({
        authContext,
        navigate
    }), [authContext, navigate]);

    const onSingleClick = (id: number, position: number) => {
        const updatedCheckStates = checkStates.map((item, index) => {
            return index === position ? !item : item;
        })
        setCheckStates(updatedCheckStates);
        const checkedCount = updatedCheckStates.filter(value => value).length;
        setSelectedAll(checkedCount === stories?.length);
        setAnyChecked(checkedCount !== 0);
    }

    const onAllCheck = () => {
        if (stories) {
            setCheckStates(new Array(stories.length).fill(!selectedAll));
            if (selectedAll) {
                // uncheck
                setSelectedAll(false);
                setAnyChecked(false)
            } else {
                setSelectedAll(true);
                setAnyChecked(true)
            }
        }
    }

    const getCheckedIds = (): number[] => {
        const checkedIds: number[] = [];
        stories?.forEach((story, index) => {
            if (checkStates[index]) {
                checkedIds.push(story.id);
            }
        })
        return checkedIds;
    }

    const onDelete = async () => {
        setDeleting(true);
        setShowDelete(false);
        setLoading(true);
        const checkedIds = getCheckedIds();
        const result = await deleteStories(serviceContext, checkedIds);
        if (result.success) {
            const newStories = stories.filter(s => checkedIds.indexOf(s.id) < 0);
            setStories(newStories)
            setCheckStates(new Array(newStories.length).fill(false))
            toast.info("Löschen erfolgreich!");
        } else {
            toast.error("Löschen fehlgeschlagen!");
        }
        setDeleting(false);
        setShowDelete(false);
        setLoading(false);
    }

    const startSortOrder = () => {
        const id2Order: { [key: number]: number } = {};
        stories.forEach(story => {
            id2Order[story.id] = story.sortOrder || -1;
        })
        setSortOrderMap({
            ...sortOrderMap,
            ...id2Order
        });
        setShowSortOrder(true);
    }

    const inputSortOrder = (id: number, order: number) => {
        setSortOrderMap({
            ...sortOrderMap,
            [id]: order
        })
    }

    const submitSortOrders = () => {
        setSorting(true);
        sortStories(serviceContext, sortOrderMap).then((res: StateResponse) => {
            if (res.success) {
                loadStories();
            } else {
                toast.error("Fehler beim Sortieren der Storys. Versuchen Sie es bitte später.")
            }
        }).finally(() => {
            setSorting(false);
        })
    }

    const loadStories = () => {
        setLoading(true);
        fetchAllStories(serviceContext).then((stories) => {
            setStories(stories)
            if (stories) {
                setCheckStates(new Array(stories.length).fill(false))
            }
        }).catch((error) => {
            console.error(error);
            toast.error("Das Abrufen von Storys ist fehlgeschlagen");
        }).finally(() => {
            setLoading(false);
            setShowSortOrder(false);
        });
    }

    useEffect(loadStories, [authContext, navigate, serviceContext])

    return (
        <div className="flex flex-col md:flex-row">
            <ConfirmPopup show={showDelete}
                          message={"Sind Sie sicher, dass Sie dieses Element löschen möchten?"}
                          onConfirm={onDelete}
                          onCancel={() => setShowDelete(false)}
            />
            <PageLoader visible={loading}/>
            {
                !loading && (
                    <div className="relative overflow-x-auto w-full h-full shadow-md sm:rounded-lg">
                        <TableActions actions={[
                            {
                                color: "red",
                                action: () => setShowDelete(true),
                                normalText: "Löschen",
                                loadingText: "Löschen...",
                                loading: deleting || sorting,
                                enabled: !deleting && anyChecked && !sorting
                            },
                            {
                                color: "blue",
                                action: () => {
                                    navigate("/admin/stories/new")
                                },
                                normalText: "Neu",
                                loading: deleting || sorting,
                                enabled: !deleting && !sorting
                            },
                            {
                                color: "blue",
                                action: () => {
                                    if (showSortOrder) {
                                        submitSortOrders();
                                    } else {
                                        startSortOrder();
                                    }
                                },
                                normalText: showSortOrder ? "Speichern" : "Sortieren",
                                loading: deleting || sorting,
                                enabled: !deleting && !sorting
                            }
                        ]}/>
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead
                                className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input type="checkbox"
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                               checked={selectedAll}
                                               onChange={onAllCheck}
                                        />
                                    </div>
                                </th>
                                {
                                    showSortOrder &&
                                    (
                                        <th scope="col" className="px-6 py-3">
                                            Befehl
                                        </th>
                                    )
                                }
                                <th scope="col" className="px-6 py-3">
                                    Titel
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Seiten
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Kurzbeschrieb
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sichtbar
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Archiviert
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Erstelldatum
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                stories && stories.map((data, index) => (
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input type="checkbox"
                                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                       checked={checkStates[index]}
                                                       onChange={() => onSingleClick(data.id, index)}
                                                />
                                            </div>
                                        </td>
                                        {
                                            showSortOrder &&
                                            (
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        value={sortOrderMap[data.id]}
                                                        onChange={(e) => {
                                                            inputSortOrder(data.id, Number(e.target.value));
                                                        }}
                                                    />
                                                </td>
                                            )
                                        }
                                        <th scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {data.title}
                                        </th>
                                        <td className="px-6 py-4">
                                            {
                                                data.pages && data.pages.map((page, index) => (
                                                    <button
                                                        data-value={page}
                                                        key={index}
                                                        className={`${["bg-black"][0]} text-white 
                    inline-block rounded-full 
                    pb-1 px-3 text-sm mr-4 mb-2 drop-shadow-md 
                    hover:scale-105 hover:brightness-105`}>
                                                        <span className="mt-1">{page}</span>
                                                    </button>
                                                ))
                                            }
                                        </td>
                                        <th className="px-6 py-4"
                                            dangerouslySetInnerHTML={{__html: `<p class="truncate max-w-48 font-normal">${convert(data.shortDescription ?? "", {})}</p>`}}>
                                        </th>
                                        <td className="px-6 py-4">
                                            {data.isVisible ? "YES" : "NO"}
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.isArchived ? "YES" : "NO"}
                                        </td>
                                        <td className="px-6 py-4">
                                            {
                                                moment(data.dateTimeEnd).format("MMMM Do YYYY")
                                            }
                                        </td>
                                        <td className="px-6 py-4">
                                            <input
                                                className="font-medium text-blue-600 dark:text-blue-500 hover:cursor-pointer"
                                                type="button"
                                                onClick={() => navigate(`/admin/stories/${data.id}`)}
                                                value="Details und Aktualisierung"
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    );
}

export default StoryList;
