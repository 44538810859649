import config from "./config.json";

class EnvLoader {

    static load(): Env {
        return config as Env;
    }

}

export default EnvLoader;