import 'css-doodle'
import Footer from "./Footer/Footer"
import Headline from "../components/Headline"
import Bubbles from "../components/Bubbles/Bubbles"

import HomeImg1 from "../static/image/Startseite-1-low.jpg"
import HomeImgMobile from "../static/image/Startseite-1-mobile-hochformat-low.jpg"
import HomeImg2 from "../static/image/Startseite-2-low.jpg"

import useWindowResize from "../hooks/useWindowResize"
import { useEffect, useRef, useState } from "react"
import ContentModule from "../components/ContentModule"
import { useLocation } from "react-router-dom"
import Container from "../components/Container"
import useStories from "../hooks/useStories"
import usePageText from "../hooks/usePageText"
import Loader from "../components/Loader"
import useRandom from "../hooks/useRandom"
import useStoryFoldStates from "../hooks/useStoryFoldStates"
import gsap from "gsap"
import { useGSAP } from '@gsap/react'
import Title from '../components/Title'

import HomeStatic1 from '../static/image/Startseite-01-low.jpeg'
import HeadlineMagic from '../components/HeadlineMagic'


const Home = () => {

  const { isMobile } = useWindowResize()
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const location = useLocation()

  const { seed } = useRandom(5)
  const { stories, loading: storiesLoading } = useStories("HOME")
  const { storyFoldStates, onModuleFoldStateChanged } = useStoryFoldStates(stories)
  const { pageText, loading: pageTextLoading } = usePageText("HOME")
  const bg1 = useRef<HTMLDivElement>(null)

  const headlineRef = useRef<HTMLDivElement>(null)


  useEffect(() => {
    if (location && location.state && location.state.logoClicked) {
      window.scrollTo(0, 0)
      location.state.logoClicked = false
    }
  }, [location, location.state])

  const handleLogoClicked = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const onMenuIsOpenChanged = (menuIsOpen: boolean) => {
    setMenuIsOpen(menuIsOpen)
  }

  useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'visible';
    }
  }, [menuIsOpen]
  )

  useGSAP(() => {
    // change background of ref: bg to HomeImg1 after 5 seconds
    gsap.to(bg1.current, { delay: 5, opacity: 0, pointerEvents: 'none' })
  }, [])
  
  return (
    <div className="bg-beige min-w-80">

      <Headline ref={headlineRef} handleLogoClick={handleLogoClicked}
        menuIsOpen={menuIsOpen}
        onMenuIsOpenChanged={onMenuIsOpenChanged}
      />

      <HeadlineMagic headlineRef={headlineRef} />

      {
        /* Second screen */
        <div>

          {
            // Bubbles header
            <div className='w-full'>
              <div className={`mx-auto max-w-6xl relative bg-cover bg-center`}
                style={{ backgroundImage: `url(${HomeImg2})`, minHeight: isMobile ? `` : "" }}>

                <Bubbles />

                {
                  // BG1
                  <div ref={bg1} className={`flex justify-center items-center text-center absolute inset-0 bg-cover bg-center z-30`}
                    style={{ backgroundImage: `url(${isMobile ? HomeImgMobile : HomeImg1})` }}>
                    <Title className={`${isMobile ? "text-4xl" : "text-5xl"}`} marginTop='0'>
                      Herzlich <br /> Willkommen
                    </Title>
                  </div>
                }
              </div>
            </div>

          }



          <div>
            {
              // Page Text
              <Container>
                <div className={`${isMobile ? "mt-6" : "mt-16"}`} dangerouslySetInnerHTML={{ __html: pageText }} />
              </Container>
            }

            <Container className='mt-6' paddingMobile={false}>
              {
                stories.map((story) => {
                  return (
                    <ContentModule
                      foldState={storyFoldStates?.find(s => s.id === story.id)?.foldState}
                      onFoldStateChanged={(unfold) => onModuleFoldStateChanged(story.id, unfold)}
                      story={story}
                    />
                  )
                })
              }
            </Container>


            {
              // Loader
              (pageTextLoading || storiesLoading) && <Loader seed={seed} />
            }

          </div>


          {
            // STATIC IMAGE
            <Container paddingMobile={false}>
              <img alt="Home" src={HomeStatic1} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16"}`} />
            </Container>
          }

        </div>
      }

      {
        <Footer />
      }
    </div>
  )
}

export default Home