import classNames from "classnames";

type Props = {
    show: boolean
    message: string
    confirmBtnName?: string
    cancelBtnName?: string
    onConfirm: () => void
    onCancel: () => void
}

const ConfirmPopup = ({show, message, onConfirm, onCancel, confirmBtnName = "YES", cancelBtnName = "NO"}: Props) => {

    return (
        <div className={classNames({
                 "flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full": true,
                 "hidden": !show
             })}>
            <div className="relative p-4 w-full max-w-sm max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="p-4 md:p-5 text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {message}
                        </h3>
                        <button type="button"
                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                onClick={() => {
                                    onConfirm()
                                }}
                        >
                            {confirmBtnName}
                        </button>
                        <button type="button"
                                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                onClick={onCancel}
                        >
                            {cancelBtnName}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPopup;