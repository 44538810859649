import { useEffect } from "react"
import BackButton from "../components/BackButton"
import Container from "../components/Container"
import Paragraph from "../components/Paragraph"
import Subtitle from "../components/Subtitle"
import Title from "../components/Title"
import useWindowResize from "../hooks/useWindowResize"

const Privacy = () => {
  const SubSubtitle = ({ children }: { children: string }) => <h3 className="font-bold mt-4">{children}</h3>
  const List = ({ children }: { children: React.ReactNode }) => <ul className="list-disc list-inside">{children}</ul>

  const { isMobile } = useWindowResize()

  const handleClickBack = () => {
    document.referrer === "" ? window.location.href = "/" : window.history.back()
  }

  useEffect (() => {
    window.scrollTo(0, 0)
  }
    , [])

  return (
    <div>

      <Container>

        <div className="relative mt-4">
          <BackButton onClick={handleClickBack} className={`
          ${isMobile ? "" : "absolute -left-14 origin-center"} 
          block scale-150  origin-top`} />
        </div>

        <Title className="mt-8 mb-4">Datenschutzerklärung</Title>

        <Paragraph>
          Zuletzt aktualisiert am: 15.02.2024
        </Paragraph>

        <Paragraph>
          Wir legen grossen Wert darauf, dass der Umgang mit Personendaten transparent ist. Diese Datenschutzerklärung gibt Auskunft darüber, welche personenbezogenen Daten wir sammeln, zu welchem Zweck und an wen wir sie weitergeben. Um eine hohe Transparenz zu gewährleisten, wird diese Datenschutzerklärung regelmässig überprüft und aktualisiert.
        </Paragraph>

        <Subtitle className="mt-4">1. Welche Dienste wir nutzen</Subtitle>

        <List>
          <li>
            <span>MailChimp</span>
          </li>

          <li>
            <span>Cloudflare Browser Insights</span>
          </li>

          <li>
            <span>Adobe Fonts</span>
          </li>

          <li>
            <span>Cloudflare
              CDN</span>
          </li>

          <li>
            <span>Cloudflare Stream</span>
          </li>
        </List>


        <Subtitle>2. Kontaktinformationen</Subtitle>

        <p>
          Bei Fragen oder Anliegen zum Schutz Ihrer Daten durch uns erreichen Sie uns jederzeit per E-Mail unter kontakt@forumbuch.ch. Verantwortlich für die Datenbearbeitungen, die über diese Website erfolgen, ist:
        </p>

        <br />

        <p>
          Buchhandlung Forum

          <br />
          Rathausstrasse 1

          <br />
          4410 Liestal

          <br />
          Schweiz

          <br />
          <br />

          <strong>
            Datenschutzverantwortliche Person:
          </strong>

          <br />
          Bettina Bieder

          <br />
          kontakt@forumbuch.ch

          <br />
        </p>


        <Subtitle>3. Allgemeine Grundsätze</Subtitle>

        <SubSubtitle>
          3.1 Welche Daten sammeln wir von Ihnen und von wem erhalten wir diese Daten
        </SubSubtitle>

        <p>
          In erster Linie bearbeiten wir Personendaten, die Sie uns übermitteln oder die wir beim Betrieb unserer Website sammeln. Unter Umständen erhalten wir Personendaten über Sie auch von Dritten. Das können folgende Kategorien sein:
        </p>

        <List>
          <li>
            Personenstammdaten (Name, Adresse, Geburtsdaten, etc.);
          </li>

          <li>
            Kontaktdaten (Handynummer, E-Mailadresse, etc.);
          </li>

          <li>
            Finanzdaten (bspw. Kontoangaben);
          </li>

          <li>
            Onlinekennungen (bspw. Cookie-Kennung, IP-Adressen);
          </li>

          <li>Standort- und Verkehrsdaten;</li>

          <li>Ton- und Bildaufnahmen;</li>

          <li>
            besonders schützenswerte Daten (bspw. biometrische Daten oder Angaben über Ihre Gesundheit).
          </li>
        </List>

        <br />

        <SubSubtitle>
          3.2 Unter welchen Voraussetzungen bearbeiten wir Ihre Daten?
        </SubSubtitle>

        <p>
          Wir behandeln Ihre Daten vertraulich und gemäss den in dieser Datenschutzerklärung festgelegten Zwecken. Wir achten dabei auf eine transparente und verhältnismässige Bearbeitung.

          <br />
          <br />
          Falls wir ausnahmsweise nicht in der Lage sind, diese Grundsätze zu befolgen, kann die Datenbearbeitung trotzdem rechtmässig sein, weil ein Rechtfertigungsgrund vorliegt. Als Rechtfertigungsgrund kommt namentlich in Frage:
        </p>

        <List>
          <li>Ihre Einwilligung;</li>

          <li>
            die Durchführung eines Vertrages oder vorvertraglicher Massnahmen;
          </li>

          <li>
            unsere berechtigten Interessen, sofern Ihre Interessen nicht überwiegen.
          </li>
        </List>

        <br />

        <SubSubtitle>
          3.3 Wie können Sie Ihre Einwilligung widerrufen?
        </SubSubtitle>

        <p>
          Haben Sie uns eine Einwilligung zur Bearbeitung Ihrer personenbezogenen Daten für bestimmte Zwecke erteilt, bearbeiten wir Ihre Daten im Rahmen dieser Einwilligung, soweit wir keinen anderen Rechtfertigungsgrund haben.

          <br />
          <br />
          Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zu widerrufen indem Sie eine E-Mail an die im Impressum genannte Adresse schicken. Bereits erfolgte Datenverarbeitungen sind davon nicht betroffen.
        </p>

        <br />

        <SubSubtitle>
          3.4 In welchen Fällen können wir Ihre Daten an Dritte weitergeben?
        </SubSubtitle>

        <br />

        <h4>a. Grundsatz</h4>

        <p>
          Wir sind unter Umständen darauf angewiesen, die Dienste Dritter oder von verbundenen Unternehmen in Anspruch zu nehmen und diese mit der Verarbeitung Ihrer Daten zu beauftragen (sog. Auftragsverarbeiter). Kategorien der Empfänger sind namentlich:
        </p>

        <List>
          <li>
            Buchhaltung, Treuhand und Revisionsunternehmen;
          </li>

          <li>
            Beratungsunternehmen (Rechtsberatung, Steuern, etc.);
          </li>

          <li>
            IT-Dienstleister (Webhosting, Support, Clouddienste, Webseitengestaltung, etc.);
          </li>

          <li>Zahlungsdienstleister;</li>

          <li>
            Anbieter von Tracking-, Conversion- und Werbedienstleistungen.
          </li>
        </List>

        <br />

        <p>
          Wir stellen sicher, dass diese Dritten und unsere verbundenen Unternehmen die Voraussetzungen des Datenschutzes einhalten und Ihre personenbezogenen Daten vertraulich behandeln.

          <br />
          <br />
          Unter Umständen sind wir auch verpflichtet, Ihre personenbezogenen Daten an Behörden bekanntzugeben.
        </p>

        <br />

        <h4>
          b. Besuch unserer Social-Media-Kanäle
        </h4>

        <p>
          Wir haben eventuell auf unserer Website Links zu unseren Social-Media-Kanälen eingebettet. Das ist für Sie jeweils ersichtlich (typischerweise über entsprechende Symbole). Klicken Sie auf die Symbole, werden Sie auf unsere Social-Media-Kanäle weitergeleitet.

          <br />
          <br />
          Die Social Media Anbieter erfahren in diesem Fall, dass Sie von unserer Website auf deren Plattform zugreifen. Die Social Media Anbieter können die so erhobenen Daten für eigene Zwecke nutzen. Wir weisen darauf hin, dass wir keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch die Betreiber erhalten.
        </p>

        <br />

        <h4>c. Weitergabe ins Ausland</h4>

        <p>
          Unter Umständen kann es im Rahmen der Auftragsbearbeitung zu Übermittlung Ihrer personenbezogenen Daten an Unternehmen im Ausland kommen. Diese Unternehmen sind im gleichen Umfang zum Datenschutz verpflichtet, wie wir selbst. Die Übermittlung kann weltweit stattfinden.

          <br />
          <br />
          Entspricht das Datenschutzniveau nicht demjenigen des der Schweiz, so nehmen wir eine vorgängige Risikoeinschätzung vor und stellen vertraglich sicher, dass der gleiche Schutz wie in der Schweiz garantiert wird (bspw. mittels der neuen Standardvertragsklauseln der EU-Kommission oder anderen, gesetzlich vorgegebenen Massnahmen). Sollte unsere Risikoeinschätzung negativ ausfallen, ergreifen wir zusätzliche technische Massnahmen zum Schutz Ihrer Daten. Sie können die Standardvertragsklauseln der EU-Kommission abrufen unter folgendem Link. https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_de
        </p>

        <br />

        <SubSubtitle>
          3.5 Wie lange bewahren wir Ihre Daten auf?
        </SubSubtitle>

        <p>
          Wir speichern personenbezogene Daten nur so lange, wie dies erforderlich ist, um die einzelnen Zwecke, zu denen die Daten erhoben wurden, zu erfüllen.

          <br />
          <br />
          Daten, die wir bei Ihrem Besuch auf unserer Website speichern, werden während zwölf Monaten aufbewahrt. Eine Ausnahme gilt für Analyse- und Trackingdaten, die länger aufbewahrt werden können.

          <br />
          <br />
          Vertragsdaten speichern wir länger, da wir dazu durch gesetzliche Vorschriften verpflichtet sind. Wir müssen insbesondere geschäftliche Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren aufbewahren. Soweit wir solche Daten von Ihnen nicht mehr zur Durchführung der Dienstleistungen benötigen, werden die Daten gesperrt und wir verwenden sie nur noch für Zwecke der Rechnungslegung und für Steuerzwecke.
        </p>

        <br />

        <SubSubtitle>
          3.6 Wie schützen wir Ihre Daten?
        </SubSubtitle>

        <p>
          Wir werden Ihre Daten sicher aufbewahren und alle angemessenen Massnahmen ergreifen, um Ihre Daten vor Verlust, Zugriff, Missbrauch oder Änderungen zu schützen.

          <br />
          <br />
          Unsere Vertragspartner und Mitarbeitende, die Zugang zu Ihren Daten haben, sind zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet. In manchen Fällen wird es erforderlich sein, dass wir Ihre Anfragen an mit uns verbundene Unternehmen weiterreichen. Auch in diesen Fällen werden Ihre Daten vertraulich behandelt.

          <br />
          <br />
          Innerhalb unserer Webseite verwenden wir das SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.
        </p>

        <br />

        <SubSubtitle>3.7 Welche Rechte haben Sie?</SubSubtitle>

        <br />

        <h4>a. Auskunftsrecht</h4>

        <p>
          Sie können jederzeit eine Auskunft über die von uns über Sie gespeicherten Daten verlangen. Wir bitten Sie, Ihr Auskunftsgesuch zusammen mit einem Identitätsnachweis an kontakt@forumbuch.ch zu senden.

          <br />
          <br />
          Sie haben ausserdem das Recht, Ihre Daten in einem gängigen Dateiformat zu erhalten, wenn wir Ihre Daten automatisiert bearbeiten, und wenn:
        </p>

        <List>
          <li>
            Sie Ihre Einwilligung für die Bearbeitung dieser Daten erteilt haben; oder
          </li>

          <li>
            Sie Daten im Zusammenhang mit dem Abschluss oder der Abwicklung eines Vertrags bekannt gegeben haben.
          </li>
        </List>

        <p>
          Wir können die Auskunft bzw. Datenherausgabe einschränken oder verweigern, wenn dies unseren gesetzlichen Verpflichtungen, berechtigten eigenen oder öffentlichen Interessen oder Interessen einer Drittperson entgegensteht.

          <br />
          <br />
          Die Bearbeitung Ihres Gesuchs unterliegt der gesetzlichen Bearbeitungsfrist von 30 Tagen. Diese Frist dürfen wir jedoch aufgrund von hohem Anfragevolumen, aus rechtlichen oder technischen Gründen oder weil wir nähere Angaben von Ihnen benötigen, verlängern. Sie werden über die Fristverlängerung rechtzeitig mindestens in Textform informiert.
        </p>

        <br />

        <h4>b. Löschung und Berichtigung</h4>

        <br />

        <p>
          Sie haben jederzeit die Möglichkeit, die Löschung oder Berichtigung Ihrer Daten zu verlangen. Wir können das Gesuch abweisen, wenn gesetzliche Vorschriften uns zur längeren bzw. unveränderten Aufbewahrung verpflichten oder ein Erlaubnistatbestand Ihrem Gesuch entgegensteht.

          <br />
          <br />
          Bitte beachten Sie, dass die Ausübung Ihrer Rechte unter Umständen im Konflikt mit vertraglichen Abmachungen stehen und entsprechende Auswirkungen auf die Vertragsdurchführung haben kann (z.B. vorzeitige Vertragsauflösung oder Kostenfolgen).
        </p>

        <br />

        <h4>c. Rechtsweg</h4>

        <p>
          Sind Sie von der Bearbeitung personenbezogener Daten betroffen, haben Sie das Recht, Ihre Rechte gerichtlich durchzusetzen oder bei der zuständigen Aufsichtsbehörde eine Meldung einzureichen. Die zuständige Aufsichtsbehörde in der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte: https://www.edoeb.admin.ch
        </p>

        <br />

        <SubSubtitle>
          3.8 Änderungen an der Datenschutzerklärung
        </SubSubtitle>

        <p>
          Wir können diese Datenschutzerklärung jederzeit ändern. Die Änderungen werden auf  veröffentlicht, Sie werden nicht gesondert darüber informiert.
        </p>

        <br />

        <Subtitle>
          4. Einzelne Datenverarbeitungsvorgänge
        </Subtitle>

        <br />

        <SubSubtitle>
          4.1 Bereitstellen der Webseite und Erstellung von Logfiles
        </SubSubtitle>

        <br />

        <h4>
          Welche Informationen erhalten wir und wie nutzen wir sie?
        </h4>

        <p>
          Indem Sie  besuchen, werden auf unseren Servern oder auf Servern von Dienstleistungen und Produkten, die wir beziehen und / oder installiert haben, bestimmte Daten automatisch zu Zwecken der Systemadministration, für statistische oder für Sicherungszwecke oder für Trackingzwecke gespeichert. Es handelt sich dabei um:
        </p>

        <List>
          <li>
            den Namen Ihres Internetserviceproviders;
          </li>

          <li>
            Ihre IP-Adresse (unter Umständen);
          </li>

          <li>
            die Version Ihrer Browser-Software;
          </li>

          <li>
            das Betriebssystem des Rechners mit dem auf die URL zugegriffen wird;
          </li>

          <li>
            das Datum und die Uhrzeit des Zugriffs;
          </li>

          <li>
            die Webseite, von der aus Sie URL besuchen;
          </li>

          <li>
            die Suchwörter die Sie benutzt haben, um die URL finden.
          </li>
        </List>

        <br />

        <h4>
          Weshalb dürfen wir diese Daten bearbeiten?
        </h4>

        <p>
          Diese Daten können keiner bestimmten Person zugeordnet werden und es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Die Speicherung der Logfiles erfolgt, um die Funktionsfähigkeit der Webseite zu garantieren und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Hierin besteht unser berechtigtes Interesse.
        </p>

        <br />

        <h4>
          Wie können Sie die Datenerfassung verhindern?
        </h4>

        <p>
          Die Daten werden nur solange gespeichert, wie dies zur Erreichung des Zweckes Ihrer Erhebung notwendig ist. Dementsprechend werden die Daten nach Beendigung jeder Sitzung gelöscht. Die Speicherung der Logfiles ist für den Betrieb der Webseite zwingend notwendig, Sie haben daher keine Möglichkeit dagegen Widerspruch zu erheben.
        </p>

        <br />
        <br />
        <br />

        <SubSubtitle>4.2 MailChimp</SubSubtitle>

        <p>
          Für den Versand von Newslettern und E-Mail-Benachrichtigungen nutzen wir den Dienst MailChimp, angeboten von The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.

          <br />
          <br />
          Wenn Sie sich für unseren Newsletter anmelden, werden die von Ihnen angegebenen Daten und Ihre E-Mail-Adresse an MailChimp übertragen, gespeichert und verarbeitet. Dies ermöglicht uns, Ihnen regelmässige Updates, Angebote und Informationen zuzusenden.

          <br />
          <br />
          Durch die Anmeldung zum Newsletter werden Ihre IP-Adresse und das Datum der Anmeldung gespeichert. Diese Speicherung dient allein dem Nachweis im Fall, dass ein Dritter eine E-Mail-Adresse missbr /aucht und sich ohne Wissen des Berechtigten für den Newsletterempfang anmeldet.

          <br />
          <br />
          MailChimp bietet umfangreiche Analysemöglichkeiten darüber, wie die versendeten Newsletter geöffnet und benutzt werden. Diese Analysen sind gruppenbezogen und werden von uns nicht zur individuellen Auswertung verwendet.

          <br />
          <br />
          Die bei MailChimp gespeicherten Daten werden gelöscht, sobald Sie sich von unserem Newsletter abmelden. Dies beeinträchtigt nicht Daten, die zu anderen Zwecken bei uns gespeichert sind.

          <br />
          <br />
          MailChimp hat eigene Datenschutzrichtlinien und -praktiken, die sich von denen unserer Website unterscheiden können. Weitere Informationen zum Datenschutz bei MailChimp finden Sie unter: https://mailchimp.com/legal/privacy/. Wenn Sie Fragen zu den von MailChimp erfassten Daten haben oder Ihre Rechte in Bezug auf diese Daten ausüben möchten, sollten Sie sich direkt an The Rocket Science Group, LLC wenden.
        </p>

        <br />
        <br />

        <SubSubtitle>
          4.3 Cloudflare Browser Insights
        </SubSubtitle>

        <p>
          Unsere Website verwendet den Dienst "Cloudflare Browser Insights" von Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA ("Cloudflare"). Dieser Dienst sammelt und analysiert Daten über die Performance und Nutzung unserer Website aus der Perspektive des Endbenutzers.

          <br />
          <br />
          Bei Ihrem Besuch auf unserer Website werden verschiedene Daten, einschliesslich Ihrer IP-Adresse, Informationen über den von Ihnen genutzten Br /owser, Ladezeiten der Webseite, Interaktionen auf der Seite und andere technische Informationen, an Cloudflare übermittelt. Diese Daten werden von Cloudflare genutzt, um uns detaillierte Berichte über die Performance unserer Website und das Nutzerverhalten zur Verfügung zu stellen.
        </p>

        <br />
        <br />

        <SubSubtitle>4.4 Adobe Fonts</SubSubtitle>

        <p>
          Adobe Fonts ist ein Service von Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA. Adobe Fonts ist ein Online-Dienst, der den Nutzern ermöglicht, hochwertige Schriftarten auf ihren Webseiten einzubinden.

          <br />
          <br />
          Wir verwenden Adobe Fonts, um bestimmte Schriftarten auf unserer Website zu integrieren. Dies ermöglicht uns, ein konsistentes und ansprechendes Design zu gewährleisten, das zur Verbesserung der Benutzererfahrung beiträgt.

          <br />
          <br />
          Um die Schriftarten bereitzustellen, kann Adobe Fonts Cookies oder andere Tracking-Technologien verwenden, um Informationen wie die IP-Adresse des Besuchers, den Browsertyp und die Version sowie andere Informationen über den Besuch der Website zu sammeln.
        </p>

        <br />
        <br />

        <SubSubtitle>4.5 Cloudflare CDN</SubSubtitle>

        <p>
          Unsere Website nutzt den Content Delivery Network (CDN) Dienst von Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA ("Cloudflare"). Ein CDN hilft, Inhalte unserer Website schneller zu liefern, indem es Kopien der Inhalte an ein Netzwerk von schnellen Lade-Servern verteilt.

          <br />
          <br />
          Wenn Sie unsere Website besuchen, wird Ihre IP-Adresse an Cloudflare übermittelt und es können Cookies in Ihrem Br /owser gesetzt werden, die für die Optimierung und Sicherheit des Dienstes erforderlich sind. Cloudflare verwendet diese Daten, um den Datenverkehr zwischen unserem Webserver und Ihrem Endgerät zu beschleunigen und um unsere Website vor Angriffen zu schützen.
        </p>

        <br />
        <br />

        <SubSubtitle>4.6 Cloudflare Stream</SubSubtitle>

        <p>
          Unsere Website verwendet den Dienst "Cloudflare Stream" von Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA ("Cloudflare"). Cloudflare Stream ist eine Videostreaming-Plattform, die es uns ermöglicht, Videos effizient zu hosten, zu streamen und zu monetarisieren.

          <br />
          <br />
          Wenn Sie Videos auf unserer Website ansehen, die über Cloudflare Stream bereitgestellt werden, werden verschiedene Daten, einschliesslich Ihrer IP-Adresse, Informationen über den von Ihnen genutzten Browser, Datum und Uhrzeit des Zugriffs sowie Interaktionsdaten mit dem Video, an Cloudflare übermittelt und dort gespeichert. Diese Daten werden verwendet, um das Video-Streaming zu ermöglichen, die Performance zu überwachen und das Nutzererlebnis zu verbessern.
        </p>

        <br />
        <br />

        <SubSubtitle>BrainBox Generatoren</SubSubtitle>

        BrainBox Generatoren ist ein Dienst der

        <a href="https://brainbox.swiss/"
          rel="nofollow noreferrer"
          target="_blank"
        >BrainBox Solutions GmbH</a>

        , um alle datenschutzrelevanten Dienste auf einer Website zu erkennen und unter anderem damit bei der Erstellung der Datenschutzerklärung zu helfen. Dabei werden keine personenbezogenen Daten erfasst oder verarbeitet.

      </Container>

    </div>
  )
}

export default Privacy