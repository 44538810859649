import EnvLoader from "../EnvLoader";
import {download} from "../utils/fileContentDownloader";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import moment from "moment";

interface MembersResponse {
    success: boolean,
    result?: {
        members: Member[]
    },
    error?: string
}

interface MemberResponse {
    success: boolean,
    result: {
        member: Member
    }
}

interface MemberDeleteResponse {
    success: boolean,
    error?: string
}

interface TokenResponse {
    success: boolean,
    result: string
}

interface PageTextsResponse {
    success: boolean,
    error?: string,
    result: PageTexts
}

interface PageTextResponse {
    success: boolean,
    result: string | null
}

interface StoryListResponse {
    success: boolean,
    error?: string,
    result?: StoryToCreate[]
}

interface StoryResponse {
    success: boolean,
    error?: string,
    result: StoryToCreate
}

type APIServiceContext = {
    authContext: AuthContext,
    navigate: NavigateFunction
}

type AuthContext = AuthContextContent | null;

const TOKEN_HEADER_KEY = "JWT_TOKEN";

const getToken = (authContext: AuthContext) => {
    if (authContext && authContext.adminCredential) {
        const {token} = authContext.adminCredential;
        if (token) {
            return token;
        }
    }
    throw new Error("Invalid token.");
}

const checkStatus = (serviceContext: APIServiceContext, response: Response) => {
    if (response.status === 401) {
        serviceContext.authContext?.logout?.(serviceContext.navigate);
    }
}

export const findMembers = async (serviceContext: APIServiceContext, page: number) => {

    const env = EnvLoader.load();

    const response = await fetch(`${env.api.endpoint}/api/members?page=${page}`, {
        method: "GET",
        headers: {
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        }
    });
    checkStatus(serviceContext, response);

    if (response.ok) {
        const membersResponse: MembersResponse = await response.json();
        if (response.ok) {
            return membersResponse.result?.members;
        } else {
            throw new Error(membersResponse.error);
        }
    }
    return [];
}

export const registerMember = async (email: string, firstName: string, lastName: string) => {

    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/members`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            firstName,
            lastName
        }),
    });
    const result = await response.json() as MemberResponse;
    return result.success;
}

export const deleteMembers = async (serviceContext: APIServiceContext, emails: string[]) => {
    const env = EnvLoader.load();
    try {
        const response = await fetch(`${env.api.endpoint}/api/members/delete`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
            },
            body: JSON.stringify({
                emails
            }),
        });
        checkStatus(serviceContext, response);

        return await response.json() as MemberDeleteResponse;
    } catch (e) {
        console.error(e);
        return {
            success: false
        }
    }
}

export const exportMembers = async (serviceContext: APIServiceContext, emails: string[]) => {
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/members/export`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        },
        body: JSON.stringify({
            emails
        }),
    });
    checkStatus(serviceContext, response);

    const headers = response.headers;
    const blob = await response.blob();
    download(blob, headers);
}

export const updateMemberGenres = async (email: string, genres: string[], visitorIdeas?: string) => {
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/members/genres`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            genres,
            visitorIdeas
        }),
    });
    const result = await response.json() as MemberResponse;
    return result.success;
}

export const generateToken = async (email: string, password: string): Promise<string> => {
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/token/generate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            password
        }),
    });
    const result = await response.json() as TokenResponse;
    return result.result;
}

export const findPageTexts = async (serviceContext: APIServiceContext): Promise<PageTexts | null> => {

    const env = EnvLoader.load();

    const response = await fetch(`${env.api.endpoint}/api/page-texts`, {
        method: "GET",
        headers: {
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        }
    });
    checkStatus(serviceContext, response);

    if (response.ok) {
        const pageTextsResponse: PageTextsResponse = await response.json();
        if (response.ok) {
            return pageTextsResponse.result;
        } else {
            throw new Error(pageTextsResponse.error);
        }
    }
    return null;
}

export const updatePageTexts = async (serviceContext: APIServiceContext, pageTexts: PageTexts | null | undefined) => {
    if (!pageTexts) {
        return false;
    }
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/page-texts`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        },
        body: JSON.stringify(pageTexts),
    });
    const result = await response.json() as {
        success: boolean
    };
    return result.success;
}

export const fetchPageText = async (pageName: PageName): Promise<string | null> => {
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/page-texts/fetch?page=${pageName}`, {
        method: "GET",
    });
    const ptResponse = await response.json() as PageTextResponse;
    return ptResponse.result;
}

export const createStory = async (serviceContext: APIServiceContext, story: StoryToCreate) => {
    if (story.id !== 0) {
        return await updateStory(serviceContext, story, story.id);
    }
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/stories`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        },
        body: JSON.stringify(story),
    });
    return await response.json() as StoryCreateResponse;
}

export const updateStory = async (serviceContext: APIServiceContext, story: StoryToCreate, id: number) => {
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/stories/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        },
        body: JSON.stringify(story),
    });
    return await response.json() as StoryCreateResponse;
}

export const sortStories = async (serviceContext: APIServiceContext, id2SortOrder: {[key: number]: number}) => {
    const env = EnvLoader.load();

    const ids: number[] = [];
    const sortOrders: number[] = [];

    Object.entries(id2SortOrder).forEach(([idStr, sortOrder]) => {
        ids.push(Number(idStr));
        sortOrders.push(sortOrder);
    })

    const response = await fetch(`${env.api.endpoint}/api/stories/sort`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        },
        body: JSON.stringify({
            ids,
            sortOrders
        }),
    });
    return await response.json() as StateResponse;
}

const convertStory = (story: StoryToCreate) => {
    if (typeof story.dateTimeStart === "string") {
        if (story.dateTimeStart !== "") {
           story.dateTimeStart = moment(story.dateTimeStart).toDate();
        } else {
            story.dateTimeStart = undefined;
        }
    }
    if (typeof story.dateTimeEnd === "string") {
        if (story.dateTimeEnd !== "") {
            story.dateTimeEnd = moment(story.dateTimeEnd).toDate();
        } else {
            story.dateTimeEnd = undefined;
        }
    }
    return story;
}

export const fetchAllStories = async (serviceContext: APIServiceContext): Promise<StoryToCreate[]> => {

    const env = EnvLoader.load();

    const response = await fetch(`${env.api.endpoint}/api/stories`, {
        method: "GET",
        headers: {
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        }
    });
    checkStatus(serviceContext, response);

    if (response.ok) {
        const listResponse: StoryListResponse = await response.json();
        if (listResponse.success) {
            return listResponse.result?.map(story => convertStory(story)) || [];
        } else {
            throw new Error(listResponse.error);
        }
    }
    return [];
}

export const fetchOptions = async (serviceContext: APIServiceContext): Promise<OptionMap> => {

    const env = EnvLoader.load();

    const response = await fetch(`${env.api.endpoint}/api/system-configs/options`, {
        method: "GET",
        headers: {
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        }
    });
    checkStatus(serviceContext, response);

    if (response.ok) {
        const res: {
            success: boolean,
            result: OptionMap,
            error?: string
        } = await response.json();
        if (res.success) {
            return res.result;
        } else {
            throw new Error(res.error);
        }
    }
    return {
        color: [],
        category: [],
        effect: []
    };
}

export const fetchStoryById = async (id: number): Promise<StoryToCreate | null> => {

    const env = EnvLoader.load();

    const response = await fetch(`${env.api.endpoint}/api/stories/${id}`, {
        method: "GET"
    });

    if (response.ok) {
        const storyResponse: StoryResponse = await response.json();
        if (storyResponse.success) {
            return convertStory(storyResponse.result);
        } else {
            throw new Error(storyResponse.error);
        }
    }
    return null;
}

export const deleteStories = async (serviceContext: APIServiceContext, ids: number[]) => {
    const env = EnvLoader.load();
    const response = await fetch(`${env.api.endpoint}/api/stories`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            [TOKEN_HEADER_KEY]: getToken(serviceContext.authContext)
        },
        body: JSON.stringify({
            ids
        }),
    });
    checkStatus(serviceContext, response);

    return await response.json() as {
        success: boolean,
        error?: string
    };

}
