import 'css-doodle'
import Footer from "./Footer/Footer"
import Headline from "../components/Headline"
import { Outlet } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import HeadlineMagic from '../components/HeadlineMagic'

const Home = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const headlineRef = useRef<HTMLDivElement>(null)

  const handleNavClose = () => {
    setMenuIsOpen(false)
  }

  const onMenuIsOpenChanged = (menuIsOpen: boolean) => {
    setMenuIsOpen(menuIsOpen)
  }

  useEffect(() => {
  window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(menuIsOpen){
      document.body.style.overflow = 'hidden';
    }    
    else{
      document.body.style.overflow = 'visible';
    }
  }, [menuIsOpen]
  )

  return (
    <div className="bg-beige min-h-screen flex flex-col">
      <Headline menuIsOpen={menuIsOpen} 
        onMenuIsOpenChanged={onMenuIsOpenChanged} 
        handleNavClose={handleNavClose} 
        ref={headlineRef}
        />

      <HeadlineMagic headlineRef={headlineRef} />


      {
        /* Second screen */
        <div className="w-full">
          <Outlet />
        </div>
      }

      <Footer />
    </div>
  )
}

export default Home