import { ReactComponent as EmailIcon } from "../../static/image/icons_e-mail.svg"
import { ReactComponent as FacebookIcon } from "../../static/image/icons_facebook.svg"
import { ReactComponent as InstaIcon } from "../../static/image/icons_insta.svg"
import SocialMediaElement from '../../components/SocialMediaElement'
import FooterBreit from './Footer-breit.png'
import FooterSchmal from './Footer-schmal.png'

import Link from '../../components/Link'
import { useEffect, useState } from "react"

const Footer = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    /* Footer */
    <div className={`flex flex-col bg-gradient-to-r from-petrol to-iron shadow-md text-white mt-auto`}>
      {
        /* Footer upper part */
        <div className="relative">
          {
            /* Top Shadow */
            <div className="absolute inset-0 shadow-[inset_0_20px_9px_-7px__rgba(0,0,0,0.2)] pointer-none z-10"></div>
          }

          {
            /* Books Background */
            <div className="absolute z-0 inset-0 h-full">
              {
                width > 768 ?
                  /* Desktop */
                  <div className={`bg-center bg-cover h-full w-full`}
                    style={{ backgroundImage: `url(${FooterBreit})` }}>
                  </div>
                  :
                  /* Mobile */
                  <div className={`h-full w-full bg-origin-content bg-center bg-no-repeat pb-16 bg-cover`}
                    style={{ backgroundImage: `url(${FooterSchmal})` }}>
                  </div>
              }
            </div>
          }

          {
            /* Footer Content */
            <div className="relative z-10 pt-8 flex flex-col">
              {
                /* Social Media */
                <div className="flex my-4 space-x-8 w-full justify-center">
                  <SocialMediaElement alt="Email" Element={EmailIcon} link="mailto:kontakt@forumbuch.ch" />
                  <SocialMediaElement alt="Facebook" Element={FacebookIcon} link="https://www.facebook.com/forumbuchliestal/" />
                  <SocialMediaElement alt="Instagram" Element={InstaIcon} link="https://www.instagram.com/forumbuch/" />
                </div>
              }

              {
                /* Adresse */
                <div className="text-center px-4 md:pb-0 mt-4">
                  <strong>Buchhandlung Forum GmbH</strong> <br />
                  <Link to="https://maps.app.goo.gl/7D3Fr6K5SZyU9TJq9">Rathausstrasse 1</Link> • 4410 Liestal <br className="md:hidden" /> <span className="md:inline-block hidden">•</span> 061 921 26 15
                </div>
              }

              {
                /* Opening hours */
                <div className="text-center px-4 md:pb-0 pb-24 mt-4">
                  <p className="text-sm">Öffnungszeiten</p>
                  <p><strong>DI – FR </strong>10 – 13, 14 – 18 Uhr</p>
                  <p><strong>SA </strong>10 – 16 Uhr</p>
                </div>
              }

              {
                /* Bottom Line */
                <div className="mt-auto pt-4 pb-4 text-center text-white text-[0.7rem] md:text-xs pt-24">
                  2024 by tb6 • <Link to="/impressum">Impressum</Link> • <Link to="/datenschutz">Datenschutz</Link> • <Link to="/admin" className="hover:underline">Admin</Link>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  )
}

export default Footer