import { useLocation, useNavigate, useParams } from "react-router-dom";
import Title from "../components/Title";
import Container from "../components/Container";
import useStoryDetail from "../hooks/useStoryDetail";
import Loader from "../components/Loader";
import useRandom from "../hooks/useRandom";
import useWindowResize from "../hooks/useWindowResize";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useEffect, useState } from "react";

import PhotoAlbum from "react-photo-album";
import BackButton from "../components/BackButton";

const Story = () => {

  let { id } = useParams();
  const { story, loading } = useStoryDetail(id)
  const { seed } = useRandom(5)

  const { isMobile } = useWindowResize()

  const [index, setIndex] = useState(-1);

  const [photos, setPhotos] = useState([] as any[])

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (story && story.photos && story.photos.length > 1) {
      setPhotos(story?.photos?.slice(1).map((photo, index) => ({
        src: photo.url,
        width: photo.width,
        height: photo.height
      })))
    }
  }, [story])

  const handleClickBack = () => {
    if(location && location.state && location.state.referrer){
      navigate(location.state.referrer)
    }
    else{
      navigate("/")
    }
  }

  return (
    <div>
      <Container className="pb-4">

      <div className="relative mt-4">
          <BackButton onClick={handleClickBack} className={`
          ${isMobile ? "" : "absolute -left-14 origin-center"} 
          block scale-150  origin-top`} />
        </div>
        <Title className="mt-8">{story?.title}</Title>

        <div dangerouslySetInnerHTML={{ __html: story?.details ?? "" }} />

        <div className="mt-8">
        <PhotoAlbum photos={photos} layout="rows" targetRowHeight={200} onClick={({ index }) => setIndex(index)} />

        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
        />
        </div>

        {
          // Loader
          loading && <Loader seed={seed} />
        }
      </Container>
    </div>
  )
}

export default Story