import BookImg1 from '../static/image/books-single/Buch-1.png'
import BookImg2 from '../static/image/books-single/Buch-2.png'
import BookImg3 from '../static/image/books-single/Buch-3.png'
import BookImg4 from '../static/image/books-single/Buch-4.png'
import BookImg5 from '../static/image/books-single/Buch-5.png'

type Props = {
  text?: string,
  seed?: number,
}

const Loader: React.FC<Props> = ({ text, seed = Math.floor(Math.random() * 5) }) => {
  return (
    <div className="text-white font-ofelia my-4">
      <div className={`w-16 h-16 animate-pulse relative inline-block 
        flex justify-center items-center 
        bg-petrol 
        rounded-full aspect-square mx-auto`}>
        <img alt="book" src={[BookImg1, BookImg2, BookImg3, BookImg4, BookImg5].at(seed)} 
          className='animate-spin w-10 h-auto overflow-visible mx-auto' />
      </div>
    </div>
  )
}

export default Loader