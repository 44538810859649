import { useParams } from "react-router-dom"
import useStoryDetail from "../hooks/useStoryDetail"
import useRandom from "../hooks/useRandom"
import Loader from "../components/Loader"
import Container from "../components/Container"
import ContactForm from "../components/ContactForm"
import Title from "../components/Title"

const Register = () => {

  const { id } = useParams()
  const { story, loading } = useStoryDetail(id)
  const { seed } = useRandom(5)

  return (
    <div className="mt-4">
      {
        // Loader
        loading ? <Loader seed={seed} />
        :
        <Container>
          <Title>Anmelden für {story?.title}</Title>

          <ContactForm story={story} />
          
        </Container>
      }
    </div>
  )
}

export default Register