import React, {Fragment} from 'react';
import {Navigate, Outlet, useNavigate} from 'react-router-dom'
import NavBar from "../../components/admin/NavBar";
import './App.scss';
import {useAuth} from "../../hooks/useAuth";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {toast, ToastContainer, Zoom} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

const App = () => {

    const navigate = useNavigate();

    const {adminCredential, logout} = useAuth();
    if (!adminCredential || !adminCredential.token) {
        return <Navigate to={"/login"} />
    }

    const handleLogout = (navigate: NavigateFunction) => {
        logout?.(navigate);
    }

    toast.dismiss();

    return (
        <Fragment>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Zoom}
            />
            <NavBar logout={() => handleLogout(navigate)}/>
            <Outlet/>
        </Fragment>
    );
}

export default App;
