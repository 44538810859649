import { PropsWithChildren } from "react"
import { Link as RouterLink } from "react-router-dom"

type LinkProps = PropsWithChildren<{
  to: string
  className?: string
}>

const Link : React.FC<LinkProps> = ({to, className, children}) => {
  return (
    <RouterLink to={to} className={`${className} underline decoration-hellgrau hover:no-underline`}>
      {children}
    </RouterLink>
  )
}

export default Link