import {useState} from 'react';
import { ReactComponent as Logo } from '../../static/image/FORUM_logo.svg';
import classNames from "classnames";
import ConfirmPopup from "../ConfirmPopup";
import {useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";

type NavBarProps = {
    logout: () => void
}

const getStatusStyles = (expectedPath: string, currentPaths: string[], fixedMatchPath?: string[]) => {
    if (fixedMatchPath && fixedMatchPath.indexOf(expectedPath) > -1) {
        return 'border-blue-600';
    }
    for (let currentPath of currentPaths) {
        if (expectedPath.startsWith(currentPath)) {
            return 'border-blue-600';
        }
    }
    return 'border-transparent';
}

function NavBar(props: NavBarProps) {

    const [logoutShow, setLogoutShow] = useState<boolean>(false);

    const {pathname} = useLocation();

    const navigate = useNavigate();
    const goTo = (path: string) => {
        navigate(path)
    }

    return (
        <div>
            <ConfirmPopup show={logoutShow}
                          message="Bist du sicher, dass du dich ausloggen möchtest?"
                          confirmBtnName="Bestätigen"
                          cancelBtnName="Abbrechen"
                          onConfirm={props.logout}
                          onCancel={() => {setLogoutShow(false)}}/>
            <nav className="bg-white border-gray-200 dark:bg-gray-900">
                <div className="flex flex-wrap justify-between items-center mx-auto p-4">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <Logo className="h-8 dark:text-white" />
                        <span
                            className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Admin</span>
                    </a>
                    <div className="flex items-center space-x-6 rtl:space-x-reverse">
                        <button onClick={() => {
                            setLogoutShow(true)
                        }} className="text-sm  text-blue-600 dark:text-blue-500 hover:underline">
                            Abmelden
                        </button>
                    </div>
                </div>
            </nav>
            <nav className="bg-gray-50 dark:bg-gray-700">
                <div
                    className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <ul className="flex flex-wrap -mb-px list-none">
                        <li className="me-2">
                            <button
                                className={classNames("inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300", getStatusStyles(pathname, ['/admin/members'], ['/admin']))}
                                onClick={() => goTo("/admin/members")}
                            >
                                Members
                            </button>
                        </li>
                        <li className="me-2">
                            <button
                                className={classNames("inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300", getStatusStyles(pathname, ['/admin/stories']))}
                                onClick={() => goTo("/admin/stories")}
                            >
                                Module
                            </button>
                        </li>
                        <li className="me-2">
                            <button
                                className={classNames("inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300", getStatusStyles(pathname, ['/admin/pageTexts']))}
                                onClick={() => goTo("/admin/pageTexts")}
                            >
                                Seiten-Texte
                            </button>
                        </li>
                        <li className="me-2">
                            <button
                                className={classNames("inline-block p-4 text-blue-600 border-b-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500", getStatusStyles(pathname, ['/admin/others']))}
                            >
                                ...
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
