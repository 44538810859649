import config from '../config.json'
import { useState } from 'react'

const useSendMail = (onResponse: (response: Response) => void) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Response>()
  const [error, setError] = useState()

  const sendMail = (subject: string, text: string) => {
    console.log("sending request")
    setLoading(true)

    fetch(`${config.email.endpoint}/messages/email`, {
      method: 'POST',
      body: JSON.stringify({
        from: config.email.from,
        to: config.email.to,
        title: subject,
        content: text,
      }),
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'X_API_KEY': `b2dvZ29ndGI2${config.email.apiKey}dGI2bW9vb29vYg==`,
        'X_APP': config.email.app,
        'X_REGION': config.email.region
      }
    }).then(res => res.json()).then(data =>{
        console.log(data)
        setData(data)
        onResponse(data)
      })
      .catch(err => {
        console.log(err)
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    sendMail, loading, data, error
  }
}

export default useSendMail