import { FieldError } from "react-hook-form"

type InputProps = {
  placeholder: string,
  register?: any,
  errors?: FieldError,
  type?: "text" | "email" | "password",
  label?: string
}

const Input : React.FC<InputProps> = ({ placeholder, register, errors, type, label }) => {
  return (
    <div className="text-left text-base">
      { label && <label className="text-stone-500">{label}</label> }
      <input
        {...register}
        className={`
          rounded-sm
          w-full p-4 mt-4 focus:outline-none  
          ${errors ? "ring-2 ring-petrol" : "focus:ring-2 focus:ring-stone-300"}
        `}
        type={type ?? "text"}
        placeholder={placeholder} 
        aria-invalid={errors ? "true" : "false"} 
        />

      {errors && <p className="ml-4 my-2 text-petrol">{errors.message}</p>}
    </div>
  )
}

export default Input