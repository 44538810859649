import { useEffect, useState } from "react"

const useWindowResize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { 
    isMobile
   }
}

export default useWindowResize