import ArrowDown from "../static/image/down-arrow-svgrepo-com.svg"

type BackButtonProps = {
  className?: string,
  onClick: () => void,
  sizeClassName?: string,
}

const BackButton: React.FC<BackButtonProps> = ({ className, onClick, sizeClassName }) => {
  return (
    <button
      className={`text-black hover:underline lowercase border-white ${className}`}
      onClick={onClick}
    >
      <img src={ArrowDown} className={`
      ${sizeClassName ?? "w-4 h-4"}
      -mt-1 rotate-90 
       inline-block mr-2
       `}
        alt="Arrow left icon" />
    </button>
  )
}

export default BackButton