import EnvLoader from "../../EnvLoader";
import ReactJson from "react-json-view";

const EnvPage = () => {

    const env: Env = EnvLoader.load();

    Object.assign(env, {
        "process.env.NODE_ENV": process.env.NODE_ENV
    })

    return (
        <div>
            <ReactJson src={env}/>
        </div>
    )

}

export default EnvPage;