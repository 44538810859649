import React, {useEffect, useRef, useState} from 'react';
import PageCheckboxes from "../../../components/admin/PageCheckboxes";
import "./StoryDetail";
import ButtonsEditor from "../../../components/admin/ButtonsEditor";
import PhotosEditor from "../../../components/admin/PhotosEditor";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth";
import {uploadImage} from "../../../services/TMSService";
import {createStory, fetchOptions, fetchStoryById} from "../../../services/APIService";
import PageLoader from "../../../components/admin/PageLoader";
import DateTimePicker from "../../../components/admin/DateTimePicker";
import {toast} from "react-toastify";

import RichTextEditor from '../../../components/admin/RichTextEditor';
import {useForm} from "react-hook-form";
import {initStory} from "./Helper";

function StoryCreate() {

    const {id} = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [optionMap, setOptionMap] = useState<OptionMap>({
        color: [],
        effect: [],
        category: []
    });
    const [photos, setPhotos] = useState<PhotoToCreate[]>([]);

    const {
        register,
        setValue,
        getValues,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm<StoryToCreate>({
        defaultValues: initStory()
    });

    const onSubmit = handleSubmit(async (story) => {
        console.log(story);
        setLoading(true);
        const response: StoryCreateResponse = await createStory(serviceContext, story);
        if (response.success) {
            clearFileUploaded();
            toast.info("Erfolgreich erstellen oder aktualisieren!");
            navigate("/admin/stories");
        } else {
            console.error(response.error);
            toast.error("Fehler beim Erstellen oder Aktualisieren!");
        }
    }, () => {
        console.log(errors);
    })

    const isCreate = !Boolean(id);

    const fileUploadRef = useRef(null);

    const navigate = useNavigate();
    const authContext = useAuth();
    const serviceContext = {
        authContext,
        navigate
    }

    const handlePhotoDelete = (photo: PhotoToCreate) => {
        if (getValues().photos) {
            const index = getValues().photos?.findIndex(p => p.id === photo.id);
            if (index !== undefined) {
                getValues().photos?.splice(index, 1);
                const photos = getValues().photos;
                if (photos) {
                    setPhotos([
                        ...photos
                    ])
                }
            }
        }
    }

    const clearFileUploaded = () => {
        if (fileUploadRef.current) {
            // @ts-ignore
            fileUploadRef.current.value = "";
            // @ts-ignore
            fileUploadRef.current.type = "text";
            // @ts-ignore
            fileUploadRef.current.type = "file";
        }
    }

    const handlePhotoFileUpload = async (file: File, onFinished: () => void) => {
        const response: ImageUploadResponse = await uploadImage(file);
        if (response.ok) {
            const data = response.data;
            const newPhoto = {
                id: data.id,
                title: "",
                width: data.width,
                height: data.height,
                url: data.src,
                thumbnailsUrl: data.thumbnail
            };
            if (getValues().photos) {
                getValues().photos?.push(newPhoto);
            } else {
                setValue("photos", [newPhoto]);
            }
            const photos = getValues().photos;
            if (photos) {
                setPhotos([
                    ...photos
                ])
            }
        } else {
            console.error(response.error);
            toast.error("Foto-Upload fehlgeschlagen!");
        }
        clearFileUploaded();
        onFinished();
    }

    useEffect(() => {
        setLoading(true);
        fetchOptions({authContext, navigate}).then(optionMap => {
            setOptionMap(optionMap);
            if (id) {
                fetchStoryById(Number(id))
                    .then((story) => {
                        if (story) {
                            reset(story);
                            if (story.photos) {
                                setPhotos([
                                    ...story.photos
                                ])
                            }
                        } else {
                            throw new Error("Cannot find story");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Das Abrufen von Storys ist fehlgeschlagen!");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                reset(initStory);
                setLoading(false);
            }
        }).catch((error) => {
            console.error(error);
            toast.error("Es können keine Optionen gefunden werden. Versuchen Sie es bitte später!");
            setLoading(false);
        });

    }, [authContext, navigate, id, reset])

    return (
        <div>
            <PageLoader visible={loading}/>
            {
                !loading && (
                    <div className="pageContainer grid grid-cols-5">
                        <div className="max-w-full mx-10 col-span-2">
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Titel *
                                </label>
                                <input type="text"
                                       className="bg-gray-50 border border-gray-3   00 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       {...register("title", {
                                           required: "Dies ist erforderlich",
                                           maxLength: {
                                               value: 128,
                                               message: "Diese Eingabe überschreitet maxLength (128 Zeichen).",
                                           }
                                       })}
                                />
                                {
                                    errors.title &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                        {errors.title.message}
                                    </p>
                                }
                            </div>
                            <div className="mb-5">
                                <label htmlFor="title"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Seiten
                                </label>
                                <PageCheckboxes
                                    pageNames={getValues().pages}
                                    onChange={(pageNames) => setValue("pages", pageNames)}
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="shortDescription"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Kurze Beschreibung
                                </label>
                                <textarea rows={3}
                                          className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          {...register("shortDescription", {
                                              maxLength: {
                                                  value: 256,
                                                  message: "Diese Eingabe überschreitet maxLength (256 Zeichen).",
                                              }
                                          })}
                                />

                                <RichTextEditor
                                    value={getValues().shortDescription}
                                    onEditorStateChange={(content) => setValue("shortDescription", content)}/>

                                {
                                    errors.shortDescription &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                        {errors.shortDescription.message}
                                    </p>
                                }

                            </div>
                            <div className="mb-5">
                                <label htmlFor="longDescription"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Lange Beschreibung
                                </label>
                                <textarea rows={7}
                                          className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          {...register("longDescription")}
                                />

                                <RichTextEditor value={getValues().longDescription}
                                                onEditorStateChange={(content) => setValue("longDescription", content)}/>

                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Details
                                </label>
                                <textarea rows={7}
                                          className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          {...register("details")}
                                />
                                <RichTextEditor value={getValues().details}
                                                onEditorStateChange={(content) => setValue("details", content)}/>

                            </div>
                            <div className="grid gap-6 mb-6 md:grid-cols-2">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Beginn
                                    </label>
                                    <DateTimePicker
                                        value={getValues().dateTimeStart}
                                        onChange={(date) => setValue("dateTimeStart", date)}
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Ende
                                    </label>
                                    <DateTimePicker
                                        value={getValues().dateTimeEnd}
                                        onChange={(date) => setValue("dateTimeEnd", date)}
                                    />
                                </div>
                            </div>


                            <div className="grid gap-6 mb-6 md:grid-cols-3">
                                <div>
                                    <label htmlFor="color"
                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Farbe
                                    </label>
                                    <select
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        {...register("color")}
                                    >
                                        {
                                            optionMap.color.length > 0 && (
                                                optionMap.color.map((c, index) => <option key={index}
                                                                                          value={c.value}>{c.name}</option>)
                                            )
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Effekte
                                    </label>
                                    <select
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        {...register("effect")}
                                    >
                                        {
                                            optionMap.effect.length > 0 && (
                                                optionMap.effect.map((c, index) => <option key={index}
                                                                                           value={c.value}>{c.name}</option>)
                                            )
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Kategorie
                                    </label>
                                    <select
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        {...register("category")}
                                    >
                                        {
                                            optionMap.category.length > 0 && (
                                                optionMap.category.map((c, index) => <option key={index}
                                                                                             value={c.value}>{c.name}</option>)
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Link
                                </label>
                                <input type="text"
                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       {...register("link")}
                                />
                                {
                                    errors.link &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                        {errors.link.message}
                                    </p>
                                }
                            </div>
                            <div className="grid gap-6 mb-6 md:grid-cols-2">
                                <div className="mb-5">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Link-Typ
                                    </label>
                                    <select
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        {...register("linkType")}
                                    >
                                        <option value="internal">intern</option>
                                        <option value="external">extern</option>
                                    </select>
                                </div>
                                <div className="mb-5">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Link-Ziel
                                    </label>
                                    <select
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        {...register("linkTarget")}
                                    >
                                        <option value="_blank">neues Fenster</option>
                                        <option value="_self">aktuelles Fenster</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    {...register("horizontalFlip")}
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Horizontal-Flip
                                </label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    {...register("centered")}
                                    type="checkbox" value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Zentriert
                                </label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    {...register("isVisible")}
                                    type="checkbox" value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Sichtbar
                                </label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    {...register("isArchived")}
                                    type="checkbox"
                                    value=""
                                    disabled={isCreate}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Archiviert (Kann nur im Update geändert werden)
                                </label>
                            </div>
                            <button onClick={onSubmit}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Speichern
                            </button>
                        </div>
                        <div className="max-w-full mx-10 col-span-1">
                            <ButtonsEditor buttons={getValues().buttons || []}
                                           handleSave={(buttons) => setValue("buttons", buttons)}/>
                        </div>
                        <div className="max-w-full mx-10 col-span-2">
                            <PhotosEditor
                                photos={photos}
                                onDelete={handlePhotoDelete}
                                onFileSelect={handlePhotoFileUpload}
                                fileUploadRef={fileUploadRef}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default StoryCreate;
