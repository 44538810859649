import { useEffect, useState } from 'react'

const useRandom = (i : number) => {

  const [seed, setSeed] = useState(0)
  
  useEffect(() => {
    let x = Math.floor(Math.random() * i)
    setSeed(x)
  }, [i])

  return { 
    seed
  }
}

export default useRandom