import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  text?: string,
  className?: string,
  marginTopClass?: string,
}>

const Subtitle : React.FC<Props> = ({ text, className, marginTopClass, children}) => {
  return (
    <div className={`
      ${className ?? ""} 
      font-garamond text-3xl italic
      ${marginTopClass ?? "mt-8"}
      `}>
      {children ?? text}
    </div>
  )
}

export default Subtitle