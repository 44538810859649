import './index.scss';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import AdminRoutes from './pages/admin/Routes';
import AdminApp from './pages/admin/App';

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import Impressum from './pages/Impressum';
import { ProtectedRoute } from "./ProtectedRoute";
import LoginPage from "./pages/admin/LoginPage";
import { AuthProvider } from "./hooks/useAuth";

import Buecher from './pages/Buecher';
import Home from './pages/Home';
import Dinge from './pages/Dinge';
import Begegnungen from './pages/Begegnungen';
import Menschen from './pages/Menschen';
import Raum from './pages/Raum';
import Story from './pages/Story';
import Privacy from './pages/Privacy';
import Register from './pages/Register';

import Error404 from './pages/Error404';
import ContactForm from './components/ContactForm';
import Container from './components/Container';
import BecomePartOfCommunity from './components/BecomePartOfCommunity';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/story/:id",
                element: <Story />,
            },
            {
                path: "/register/:id",
                element: <Register />,
            },

            {
                path: "/buecher",
                element: <Buecher />,
            },
            {
                path: "/dinge",
                element: <Dinge />,
            },
            {
                path: "/begegnungen",
                element: <Begegnungen />,
            },
            {
                path: "/menschen",
                element: <Menschen />,
            },
            {
                path: "/raum",
                element: <Raum />,
            },
            {
                path: "/impressum",
                element: <Impressum />,
            },
            {
                path: "/datenschutz",
                element: <Privacy />,
            },
            {
                path: "/kontakt",
                element: 
                    <Container className='mt-8'>
                        <ContactForm />
                    </Container>
            },
            {
                path: "/teil-werden",
                element: 
                    <Container className='mt-8'>
                        <BecomePartOfCommunity />
                    </Container>
            }
        ]
    },
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/login",
        element: [
            <LoginPage />
        ]
    },
    {
        path: "/admin",
        element: (
            <ProtectedRoute>
                <AdminApp />
            </ProtectedRoute>
        ),
        children: AdminRoutes
    },
    {
        path: "*",
        element: <App />,
        children: [
            {
                path: "*",
                element: <Error404 />,
            }
        ]
    }
]);

createRoot(document.getElementById("root") as HTMLElement).render(
    <AuthProvider>
        <RouterProvider router={router} />
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
