import { Link, useLocation } from "react-router-dom"
import useWindowResize from "../hooks/useWindowResize"
import LinkButton from "./form/LinkButton"

const nav = [
  {
    name: 'Bücher',
    link: '/buecher'
  },
  {
    name: 'Dinge',
    link: '/dinge'
  },
  {
    name: 'Begegnungen',
    link: '/begegnungen'
  },
  {
    name: 'Menschen',
    link: '/menschen'
  },
  {
    name: 'Raum',
    link: '/raum'
  }
]

type Props = {
  onClose?: () => void
  className?: string
  mobileClassName?: string
}
const Nav: React.FC<Props> = ({ onClose, className, mobileClassName }) => {

  const { isMobile } = useWindowResize()

  // get active page
  const location = useLocation()

  const handleLinkClick = () => {
    window.scrollTo(0, 0)
    onClose && onClose()
  }

  return (
    isMobile ? (
      // Mobile Nav
      <nav className={`font-ofelia uppercase text-center text-4xl px-4 h-full ${mobileClassName ?? ""}`}>
        <ul className="mt-4 flex flex-col h-full list-none">
          {
            nav.map((item, index) => {
              return (
                <li key={index} className="px-4 border-t border-hellgrau py-3 ">
                  <Link style={{ verticalAlign: "-moz-middle-with-baseline" }} onClick={handleLinkClick} className={`hover:scale-105 transition duration-400 inline-block ${item.link === location.pathname ? "text-petrol" : ""}`} to={item.link}>{item.name}</Link>
                </li>)
            }

            )
          }

          {
            /* Shop Button */
            <li className="mt-auto px-4 mb-36 pt-4">
              <LinkButton to="https://shop.forumbuch.ch" isExternal={true} className="scale-150" text="Shop">
                </LinkButton>
            </li>
          }
        </ul>
      </nav>
    ) : (
      // Desktop Nav
      <nav className={`pb-4 mt-2 ${className ?? ""}`}>
        <ul className="flex justify-center items-center text-xl font-ofelia uppercase list-none">
          {
            nav.map((item, index) => (
              <li key={index} className="px-4 border-t border-b border-hellgrau pb-1 pt-2">
                <Link onClick={handleLinkClick} className={`no-underline hover:scale-105 transition duration-400 inline-block  ${item.link === location.pathname ? "text-petrol" : ""} `} to={item.link}>{item.name}</Link>
              </li>
            ))
          }

          <LinkButton to="https://shop.forumbuch.ch" isExternal={true} className="ml-4">Shop</LinkButton>

        </ul>
      </nav>
    )
  )
}

export default Nav