import { ReactComponent as UnfoldSvg } from '../static/image/Zeichen-1px_aufklappen.svg'

type Props = {
  unfold: boolean
  onFoldStateChanged?: (unfold: boolean) => void
}

const UnfoldIcon: React.FC<Props> = ({ onFoldStateChanged, unfold }) => {
  const handleToggle = () => {
    onFoldStateChanged?.(!unfold)
  }

  return (
    <UnfoldSvg
      onClick={handleToggle}
      className={`w-12 h-12 stroke-2 transition duration-400 ${!unfold ? 'rotate-180' : ''} hover:text-hellgrau cursor-pointer`}
    />
  )
}

export default UnfoldIcon