import Subtitle from './Subtitle'
import Paragraph from './Paragraph'
import BookGenreChoice from '../pages/BookGenreChoice'
import Button from './form/Button'
import Input from './form/Input'
import Form from './Form'
import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'

import BackButton from './BackButton'
import confetti from 'canvas-confetti'
import { registerMember, updateMemberGenres } from "../services/APIService";
import LinkButton from './form/LinkButton'

enum pages {
  PersonalData = 0,
  BookGenreChoice = 1,
  Thanks = 2,
}

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
};

type BecomePartOfCommunityProps = {
  className?: string,
  onPageChange?: () => void,
  onClose?: () => void
}


const BecomePartOfCommunity = (props: BecomePartOfCommunityProps) => {
  const becomeAPartRef = useRef<HTMLDivElement>(null)

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    }
  });

  const [activePage, setActivePage] = useState<pages>(pages.PersonalData)

  const handleSetActivePage = (page: pages) => {
    setActivePage(page)

    if (page === pages.Thanks) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: ['#237073', '#dfd800', '#490f34', '#64b949', '#f08300'],
      });
    }
  }

  const [registeredEmail, setRegisteredEmail] = useState<string>("");
  const [genreSubmitIsLoading, setGenreSubmitIsLoading] = useState<boolean>(false)
  const [userSubmitIsLoading, setUserSubmitIsLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>("")

  const onSubmit = (data: any) => {
    setUserSubmitIsLoading(true)
    // TODO handle failure case
    registerMember(data.email, data.firstName, data.lastName)
      .then((success) => {
        if (success) {
          setRegisteredEmail(data.email);
          handleSetActivePage(pages.Thanks)
          setError("")
        } else {
          // error
          setError("Fehler beim Registrieren. Ihre Adresse ist möglicherweise bereits registriert.")
        }

      })
      .catch(() => {
        // error
        setError("Fehler beim Registrieren. Bitte kontaktieren Sie uns direkt.")

      })
      .finally(() => {
        setUserSubmitIsLoading(false)
      }
      )
  };

  const onGenreSubmit = (genres: string[], visitorIdeas?: string) => {
    setGenreSubmitIsLoading(true)

    // TODO handle failure case
    updateMemberGenres(registeredEmail, genres, visitorIdeas)
      .then((result) => {
        handleSetActivePage(pages.Thanks)
        setGenreSubmitIsLoading(false)
      })
      .catch(() => {
        setGenreSubmitIsLoading(false)
      })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (props.onPageChange) {
      props.onPageChange()
    }
  }, [props, activePage])

  return (
    <div className={props.className} ref={becomeAPartRef}>
      {
        /* Close button */
        props.onClose && (
          <button
            onClick={props.onClose}
            className="absolute top-0 right-0 mt-4 mr-4 hover:text-petrol focus:outline-none"
          >X</button>
        )
      }
      {
        /* Interested? */
        <div className="text-base my-4">
          {
            activePage === pages.BookGenreChoice ? (
              /* Book genre choice ( Page 2 ) */
              <div>
                <BackButton className="absolute top-0 left-0" onClick={() => handleSetActivePage(pages.PersonalData)} />
                <Subtitle className="mt-8" text="Danke für Ihre Anmeldung!" />
                <Paragraph className="my-4">
                  Wir halten Sie auf dem Laufenden.
                </Paragraph>
                <Paragraph className='my-4'>
                  Da wir neben der Buchhandlung auch einen Ort für Veranstaltungen planen, würde es uns interessieren, welche Art von Veranstaltungen bei Ihnen das Interesse weckt.
                </Paragraph>

                <BookGenreChoice loading={genreSubmitIsLoading} onSubmit={onGenreSubmit} />
              </div>
            )
              :
              activePage === pages.PersonalData ? (
                /* Personal data ( Page 1 ) */
                <div>
                  <Paragraph className="mt-4 text-center">Interessiert?</Paragraph>
                  <Subtitle className='text-center'>Dann werden Sie Teil des Forums!</Subtitle>
                  <Paragraph className='text-center'>
                    Und wir halten Sie auf dem Laufenden.
                  </Paragraph>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Input type="text"
                      register={register("firstName", { required: "Bitte geben Sie Ihren Vornamen ein." })}
                      errors={errors.firstName}
                      placeholder="Ihr Vorname" />

                    <Input type="text"
                      register={register("lastName", { required: "Bitte geben Sie Ihren Nachnamen ein." })}
                      errors={errors.lastName}
                      placeholder="Ihr Nachname" />

                    <Input type="email"
                      register={register("email", { required: "Bitte geben Sie Ihre Email ein." })}
                      errors={errors.email}
                      placeholder="Ihre E-Mail" />

                    <Button loading={userSubmitIsLoading} type="submit"
                      className="mx-auto mt-6 mb-2" text='Absenden' />
                  </Form>
                </div>
              )
                :
                (
                  <div className='flex flex-col justify-center text-center my-auto'>
                    {
                      /* Thanks ( Page 3 ) */
                      <>

                        <Subtitle marginTopClass='mt-0' text="Willkommen im Forum!" />
                        <LinkButton to="/" className="mt-4 whitespace-nowrap">Zurück zur Startseite</LinkButton>
                      </>
                    }
                  </div>

                )
          }


          {
            error &&
            <div className="text-red-500 text-center mt-4">{error}</div>
          }
        </div>
      }
    </div>
  )
}

export default BecomePartOfCommunity