import { useEffect, useMemo, useState } from "react"
import Button from "../components/form/Button"
import Paragraph from "../components/Paragraph"
import Input from "../components/form/Input"
import { useForm } from "react-hook-form"

type BookGenreChoiceProps = {
  onSubmit?: (genres: string[], visitorIdeas: string) => void,
  loading: boolean,
}

const BookGenreChoice = (props: BookGenreChoiceProps) => {
  const [selectedGenres, setSelectedGenres] = useState<string[]>([])
  const [genreList, setGenreList] = useState<string[]>([])
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true)
  const [genreColorMap, setGenreColorMap] = useState<{ [key: string]: string }>({})

  type FormDataType = {
    visitorIdeas: string;
  };

  const { register, watch, handleSubmit } = useForm<FormDataType>({
    defaultValues: {
      visitorIdeas: "",
    }
  });

  /*
  const [genres, setGenres] = useState<string[]>([
    "Belletristik",
    "Krimi und Thriller",
    "Science-Fiction",
    "Fantasy",
    "Historische Romane",
    "Biografien und Memoiren",
    "Kinder- und Jugendbücher",
    "Sachbuch",
    "Ratgeber",
    "Reise",
    "Kochbücher",
    "Gesundheit und Fitness",
    "Politik und Geschichte",
    "Wirtschaft und Finanzen",
    "Religion und Spiritualität",
    "Kunst und Fotografie",
    "Bildung und Lernen",
    "Naturwissenschaften und Technik",
    "Psychologie",
    "Lyrik"
  ])
  */

  const GENRES_ARRAY =
    useMemo(() => [
      "Lesungen",
      "Vorträge",
      "Diskussionsrunden",
      "Kunstausstellungen",
      "Meditationsabende",
      "Yoga",

      "Kaffikränzchen",
      "Selbsthilfegruppen",
      "Workshop-Reihen",
      "Literaturkreise",
      "Kreativwerkstätten",
      "Buchclubs",
      "Autorenbegegnungen",
      "Poesieabende",
      "Schreibwerkstätten",
      "Philosophiekreise",
      "Geschichtenerzählungen",
      "Bildungsseminare",
      "Themenabende",
      "Vorlesestunden für Kinder",
      "Debattierclubs",
      "Sprachcafés",
      "Brettspielabende",
      "Comic-Workshops",
      "Kunsthandwerkskurse",
      "Musikalische Darbietungen",
      "Film- und Literaturdiskussionen",
      "Kalligrafie-Workshops",
      "Umwelt- und Nachhaltigkeitsdiskussionen",
      "Technologie- und Digitalisierungsvorträge",
      "Gesundheits- und Wellness-Workshops",
    ], [])

  const [genres, setGenres] = useState<string[]>(GENRES_ARRAY)

  const handleSaveGenres = (visitorIdeas: string) => {
    props.onSubmit && props.onSubmit(selectedGenres, visitorIdeas)
  }

  const handleRemoveGenre = (genre: string) => {
    /* remove genre from selected genres */
    setSelectedGenres(selectedGenres.filter(g => g !== genre))

    /* add genre back to genres */
    handleModifyGenres({ add: genre })
  }

  type ModifyGenresProps = {
    add?: string,
  }

  const handleModifyGenres = ({ add }: ModifyGenresProps) => {
    const list = add ? [add, ...genres] : genres;

    /* set genres */
    setGenres(list)
  }

  const selectGenre = (event: React.MouseEvent<HTMLButtonElement>) => {
    const genre = (event.currentTarget as HTMLButtonElement).getAttribute("data-value") as string;
    (event.target as HTMLButtonElement).classList.add(..."text-white transition duration-500 ease-in-out opacity-0".split(" "))

    setTimeout(() => {
      /* remove genre from genres */
      setGenres(genres.filter(g => g !== genre))

      /* add genre to selected genres */
      if (!selectedGenres.includes(genre)) {
        setSelectedGenres([...selectedGenres, genre])
      }

      /* update genre list */
      setGenreList(genres.filter(g => !selectedGenres.includes(g) && g !== genre).slice(0, genreList.length || 5));

      /* remove class */
      (event.target as HTMLButtonElement).classList.remove(..."text-white transition duration-500 ease-in-out opacity-0".split(" "))
    }, 500)
  }

  const onSubmit = ({visitorIdeas}: FormDataType) => {
    handleSaveGenres(visitorIdeas)
  }

  useEffect(() => {
    const colors = ["bg-petrol", "bg-gelb", "bg-violet", "bg-gruen", "bg-orange"]

    /* assign a color for each genre */
    setGenreColorMap(GENRES_ARRAY.reduce((acc, genre, i) => {
      acc[genre] = colors[i % 5]
      return acc
    }, {} as { [key: string]: string }))
  }, [GENRES_ARRAY])

  useEffect(() => {
    /* set displayed genre list */
    setGenreList(genres.slice(0, genreList.length || 6))

    /* show more button */
    setShowMoreButton(genreList.length < genres.length)
  }, [genres, genreList.length, selectedGenres.length])

  return (
    <div className="my-8">
      {
        selectedGenres.length > 0 && (
          <div className="flex flex-wrap my-4">
            {
              // Display selected genres
              selectedGenres.map((genre, index) => {
                return (
                  <button
                    className="relative cursor-default bg-stone-700 rounded-full 
                       pb-1 px-2 mr-4 mb-2 text-white text-sm drop-shadow-md "
                    key={index}
                  >
                    {genre}

                    {
                      /* Remove genre from selected genres */
                    }
                    <span className="bg-white text-black rounded-full h-6 w-6 table-cell align-middle absolute -top-3 -right-3 text-sm cursor-pointer"
                      onClick={() => handleRemoveGenre(genre)}>
                      x
                    </span>
                  </button>
                )
              })
            }
          </div>
        )
      }

      {
        // Display selection of 5 genres
        <div className="flex flex-wrap my-4">
          {
            genreList.map((genre, index) => {
              return (
                <button
                  data-value={genre}
                  onClick={selectGenre}
                  key={index}
                  className={`${genreColorMap[genre]} text-white 
                    inline-block rounded-full 
                    pb-1 px-3 text-sm mr-4 mb-2 drop-shadow-md 
                    hover:scale-105 hover:brightness-105`}>
                  <span className="mt-1">{genre}</span>
                </button>
              )
            })
          }

        </div>
      }

      {
        showMoreButton && <button className="text-sm block underline hover:no-underline"
          onClick={() => {
            if (genreList.length + selectedGenres.length + 5 >= genres.length) {
              setGenreList(genres.slice(0, genres.length))
            } else {
              setGenreList(genres.slice(0, genreList.length + 5))
            }
          }
          }>
          <span className="mt-1">weitere anzeigen...</span>
        </button>
      }

      {
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            /* Own ideas */
            <>
              <Paragraph className='my-4'>
                Gerne können Sie Ihre eigenen Ideen anbringen.
              </Paragraph>

              <Input register={register("visitorIdeas", { required: false })} type="text" placeholder="Ihre Ideen" />
            </>
          }

          {
            <Button
              className="my-4"
              disabled={selectedGenres.length === 0 && !watch("visitorIdeas")}
              type="submit"
              loading={props.loading}
              >
              Absenden
            </Button>
          }
        </form>
      }

    </div>
  )
}

export default BookGenreChoice;