import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  paddingMobile?: boolean,
  className?: string
}>


const Container : React.FC<Props> = ({paddingMobile = true, className, children}) => {


  return (
    <div className={`mx-auto container 
      ${paddingMobile ? "px-4" : "px-0"} 
      sm:px-12 md:px-8 lg:px-32 xl:px-48
      ${className ?? ""}
      `}>
      {children}
    </div>
  )
}

export default Container