import BegegnungenBackgroundImage from '../static/image/Begegnung-low.jpg'
import { ReactComponent as BegegnungenSvg } from "../components/Bubbles/Begegnungen.svg"
import ContentModule from '../components/ContentModule'
import Container from '../components/Container'
import usePageText from '../hooks/usePageText'
import useStories from '../hooks/useStories'
import Loader from '../components/Loader'
import useRandom from '../hooks/useRandom'
import useStoryFoldStates from '../hooks/useStoryFoldStates'
import BegegnungenStatic1 from '../static/image/Begegnung-01-low.jpeg'
import Header from '../components/Header'
import useWindowResize from '../hooks/useWindowResize'

const Begegnungen = () => {

  const { pageText, loading: pageTextLoading } = usePageText("EVENTS")
  const { stories, loading: storiesLoading } = useStories("EVENTS")
  const { storyFoldStates, onModuleFoldStateChanged } = useStoryFoldStates(stories)

  const { seed } = useRandom(5)

  const { isMobile } = useWindowResize()

  return (
    <div>

<Header Svg={BegegnungenSvg} BgImage={BegegnungenBackgroundImage} />

      {
        // Page Text
        <Container>
          <div className={`${isMobile ? "mt-6" : "mt-16"}`}  dangerouslySetInnerHTML={{ __html: pageText }} />
        </Container>
      }

      {
        // STORIES
        <Container className='mt-6' paddingMobile={false}>
          {
            stories.map((story) => {
              return (
                <ContentModule
                  foldState={storyFoldStates?.find(s => s.id === story.id)?.foldState}
                  onFoldStateChanged={(unfold) => onModuleFoldStateChanged(story.id, unfold)}
                  story={story} />
              )
            })
          }
        </Container>
      }

      {
        // Loader
        (pageTextLoading || storiesLoading) && <Loader seed={seed} />
      }


      {
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Begegnungen" src={BegegnungenStatic1} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }

    </div>

  )
}

export default Begegnungen