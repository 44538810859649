import { useEffect, useState } from "react"
import config from "../config.json"

const useStories = (pageName: string) => {
  const [stories, setStories] = useState([] as StoryToCreate[])
  const [loading, setLoading] = useState(false)

  // Retrieve stories from API
  useEffect(() => {
    setLoading(true)
    fetch(`${config.api.endpoint}/api/stories/page/{PAGE}?page=${pageName}`).then((res) => {
      res.json().then((data) => {
        setStories(data.result.stories)
        setLoading(false)
      })
    }).catch(() => {
      console.error("There was an error fetching stories.")
      setLoading(false)
    })
  }, [pageName])


  return {
    stories,
    loading
  }
}

export default useStories