import { useEffect } from "react"
import Title from "../components/Title"
import Container from "../components/Container"
import Subtitle from "../components/Subtitle"
import Paragraph from "../components/Paragraph"
import Link from "../components/Link"
import BackButton from "../components/BackButton"
import useWindowResize from "../hooks/useWindowResize"

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { isMobile } = useWindowResize()

  const handleClickBack = () => {
    document.referrer === "" ? window.location.href = "/" : window.history.back()
  }

  return (
    <div>
      <Container>
      <div className="relative mt-4">
          <BackButton onClick={handleClickBack} className={`
          ${isMobile ? "" : "absolute -left-14 origin-center"} 
          block scale-150  origin-top`} />
        </div>
        <Title>Impressum</Title>

        <Subtitle>Kontakt</Subtitle>
        <Paragraph>
          Buchhandlung Forum<br />
          Rathausstrasse 1<br />
          4410 Liestal<br />
          <Link to="mailto:kontakt@forumbuch.ch">kontakt@forumbuch.ch</Link>
        </Paragraph>

        <Subtitle>Webseite</Subtitle>
        <Paragraph>
          Gestaltung: <Link to="https://formergrafik.ch">Former Grafik</Link>
          <br />
          Programmierung: Florian Rudin
        </Paragraph>
      </Container>
    </div>
  )
}

export default Impressum