import Bubble from "./Bubble"

interface HeaderProps {
  Svg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  BgImage: string
  bubbleText?: string
}

const Header: React.FC<HeaderProps> = ({ Svg, BgImage, bubbleText }) => {
  return (
    // Header
    <header className="max-w-6xl mx-auto flex justify-center items-center w-full h-[20rem] bg-petrol bg-cover bg-center" style={{ backgroundImage: `url(${BgImage})` }}>
      {
        <Bubble
          text={bubbleText ?? ""}
          bubbleClassName='relative w-56 h-56'
          Svg={Svg}
          svgClassName='absolute w-56 h-56 inset-0'
          bgClassName='w-56 h-56 rounded-full bg-petrol mix-blend-multiply' />
      }
    </header>
  )
}

export default Header