import { PropsWithChildren } from "react"

type FormProps = {
  onSubmit: (data: React.FormEvent<HTMLFormElement>) => void
} 


const Form = (props: PropsWithChildren<FormProps>) => {
  return (
    <form onSubmit={props.onSubmit} className="flex flex-col max-w-md mx-auto">
      {props.children}
    </form>
  )
}

export default Form