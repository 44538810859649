import React, {useEffect, useState} from 'react';
import {deleteMembers, exportMembers, findMembers} from "../../../services/APIService";
import {useAuth} from "../../../hooks/useAuth";
import TableActions from "../../../components/admin/TableActions";
import ConfirmPopup from "../../../components/ConfirmPopup";
import {useNavigate} from "react-router-dom";
import PageLoader from "../../../components/admin/PageLoader";
import {toast} from "react-toastify";

const MemberList = () => {

    const [members, setMembers] = useState<Member[]>([]);
    const [loaded, setLoaded] = useState(false);

    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [checkStates, setCheckStates] = useState<boolean[]>(new Array(members.length).fill(false));
    const [anyChecked, setAnyChecked] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);

    const [deleting, setDeleting] = useState<boolean>(false)
    const [exporting, setExporting] = useState<boolean>(false)

    const navigate = useNavigate();

    const authContext = useAuth();

    const onSingleClick = (email: string, position: number) => {
        const updatedCheckStates = checkStates.map((item, index) => {
            return index === position ? !item : item;
        })
        setCheckStates(updatedCheckStates);
        const checkedCount = updatedCheckStates.filter(value => value).length;
        setSelectedAll(checkedCount === members?.length);
        setAnyChecked(checkedCount !== 0);
    }

    const onAllCheck = () => {
        if (members) {
            setCheckStates(new Array(members.length).fill(!selectedAll));
            if (selectedAll) {
                // uncheck
                setSelectedAll(false);
                setAnyChecked(false)
            } else {
                setSelectedAll(true);
                setAnyChecked(true)
            }
        }
    }

    const getCheckedEmails = (): string[] => {
        const checkedEmails: string[] = [];
        members?.forEach((member, index) => {
            if (checkStates[index]) {
                checkedEmails.push(member.email);
            }
        })
        return checkedEmails;
    }

    const onDelete = async () => {
        setDeleting(true);
        setShowDelete(false);
        const checkedEmails = getCheckedEmails();
        const result = await deleteMembers({
            authContext, navigate
        }, checkedEmails);
        if (result.success) {
            const newMembers = members.filter(m => checkedEmails.indexOf(m.email) < 0);
            setMembers(newMembers)
            setCheckStates(new Array(newMembers.length).fill(false))
            toast.info("Löschen erfolgreich!");
        } else {
            toast.error("Löschen fehlgeschlagen!");
        }
        setDeleting(false);
        setShowDelete(false);
    }

    const onExport = async () => {
        setExporting(true);
        const checkedEmails = getCheckedEmails();
        try {
            await exportMembers({
                authContext, navigate
            }, checkedEmails);
        } catch (e) {
            console.error(e);
            toast.error("Export fehlgeschlagen! Bitte versuchen Sie es später.");
        }
        setExporting(false);
    }

    useEffect(() => {
        setLoaded(false)
        findMembers({
            authContext, navigate
        }, 0)
            .then((members) => {
                setMembers(members || [])
                if (members) {
                    setCheckStates(new Array(members.length).fill(false))
                }
            }).catch((e) => {
                console.error(e);
                toast.error("Das Laden der Mitgliederliste ist fehlgeschlagen. Versuchen Sie es bitte später.")
            }).finally(() => {
                setLoaded(true);
        })
    }, [authContext, navigate])

    return (
        <div className="flex flex-col md:flex-row">
            <ConfirmPopup show={showDelete}
                          message={"Sind Sie sicher, dass Sie dieses Element löschen möchten?"}
                          onConfirm={onDelete}
                          onCancel={() => setShowDelete(false)}
            />
            <PageLoader visible={!loaded}/>
            {
                loaded && (
                    <div className="relative overflow-x-auto w-full h-full shadow-md sm:rounded-lg">
                        <TableActions actions={[
                            {
                                color: "red",
                                action: () => setShowDelete(true),
                                normalText: "Löschen",
                                loadingText: "Löschen...",
                                loading: deleting,
                                enabled: !deleting && !exporting && anyChecked
                            },
                            {
                                color: "blue",
                                action: onExport,
                                normalText: "Export",
                                loadingText: "Exportieren...",
                                loading: exporting,
                                enabled: !deleting && !exporting && anyChecked
                            }
                        ]}/>
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead
                                className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input type="checkbox"
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                               checked={selectedAll}
                                               onChange={onAllCheck}
                                        />
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Vorname
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Nachname
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    E-Mail
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Zeit
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Genre
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Besucherideen
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                members && members.map((data, index) => (
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input type="checkbox"
                                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                       checked={checkStates[index]}
                                                       onChange={() => onSingleClick(data.email, index)}
                                                />
                                            </div>
                                        </td>
                                        <th scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {data.firstName}
                                        </th>
                                        <td className="px-6 py-4">
                                            {data.lastName}
                                        </td>
                                        <th className="px-6 py-4">
                                            {data.email}
                                        </th>
                                        <td className="px-6 py-4">
                                            {data.registerDate}
                                        </td>
                                        <td className="px-6 py-4">
                                            {
                                                data.interesting && data.interesting.map((genre, index) => (
                                                    <button
                                                        data-value={genre}
                                                        key={index}
                                                        className={`${["bg-black"][0]} text-white 
                    inline-block rounded-full 
                    pb-1 px-3 text-sm mr-4 mb-2 drop-shadow-md 
                    hover:scale-105 hover:brightness-105`}>
                                                        <span className="mt-1">{genre}</span>
                                                    </button>
                                                ))
                                            }
                                        </td>
                                        <td className="px-6 py-4">
                                            {data.visitorIdeas}
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    );
}

export default MemberList;
