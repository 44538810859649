import DingeBackgroundImage from '../static/image/Dinge-low.jpg'

import { ReactComponent as DingeSvg } from "../components/Bubbles/Dinge.svg"
import ContentModule from '../components/ContentModule'
import Container from '../components/Container'
import Loader from '../components/Loader'
import usePageText from '../hooks/usePageText'
import useStories from '../hooks/useStories'
import useRandom from '../hooks/useRandom'
import useStoryFoldStates from '../hooks/useStoryFoldStates'

import DingeStatic1 from '../static/image/Dinge-01-low.jpeg'
import DingeStatic2 from '../static/image/Dinge-02-low.jpeg'
import Header from '../components/Header'
import useWindowResize from '../hooks/useWindowResize'

const Dinge = () => {

  const { pageText, loading: pageTextLoading } = usePageText("DINGE")
  const { stories, loading: storiesLoading } = useStories("DINGE")
  const { storyFoldStates, onModuleFoldStateChanged } = useStoryFoldStates(stories)

  const { isMobile } = useWindowResize()
  const { seed } = useRandom(5)

  return (
    <div>

        <Header Svg={DingeSvg} BgImage={DingeBackgroundImage} />


      {
        // Page Text
        <Container>
          <div className={`${isMobile ? "mt-6" : "mt-16"}`}  dangerouslySetInnerHTML={{ __html: pageText }} />
        </Container>
      }

      {
          // STORIES
          <Container className='mt-6' paddingMobile={false}>
            {
              stories.map((story) => {
                return (
                  <ContentModule 
                  foldState={storyFoldStates?.find(s => s.id === story.id)?.foldState}
                  onFoldStateChanged={(unfold) => onModuleFoldStateChanged(story.id, unfold)}
                  story={story} />
                )
              })
            }
          </Container>
      }

      {
        // Loader
        (pageTextLoading || storiesLoading) && <Loader seed={seed} />
      }


{
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Dinge 1" src={DingeStatic1} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }

{
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Dinge 2" src={DingeStatic2} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }


    </div>

  )
}

export default Dinge