import { Link } from "react-router-dom"
import Button from "./Button"
import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  to: string,
  onClick?: () => void,
  className?: string,
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  id?: string,
  isExternal?: boolean,
  text?: string
}>

const LinkButton : React.FC<Props> = ({ text, to, onClick, className, onMouseEnter, id, isExternal, children }) => {
  return (
    <Link to={to} target={isExternal ? "_blank" : "self"}>
      <Button id={id} isExternal={isExternal} onClick={onClick} 
        onMouseEnter={onMouseEnter} className={className} text={text}>
        {children}
      </Button>
    </Link>
  )
}

export default LinkButton