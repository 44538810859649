import { useRef } from "react"
import UnfoldIcon from "./UnfoldIcon"
import { gsap } from "gsap"

import Button from "./form/Button"
import { useLocation, useNavigate } from "react-router-dom"
import { useGSAP } from "@gsap/react"
import Title from "./Title"

import 'css-doodle'
import useWindowResize from "../hooks/useWindowResize"

const BG_COLORS = {
  "beige": "bg-beige",
  "hellbeige": "bg-hellbeige",
  "petrol": "bg-petrol",
  "orange": "bg-orange",
  "gelb": "bg-gelb",
  "violet": "bg-violet",
  "gruen": "bg-gruen",
  "hellgrau": "bg-hellgrau",
  "iron": "bg-iron",
}

type Props = {
  story: StoryToCreate
  className?: string
  onFoldStateChanged?: (unfold: boolean) => void
  foldState?: boolean
}

const ContentModule: React.FC<Props> = ({ story, className, onFoldStateChanged, foldState = false }) => {

  const bgColor = BG_COLORS[story.color as keyof typeof BG_COLORS]
  const isInvertColor = ["petrol", "violet"].some(col => col === story.color)

  const hasContent = (html?: string) => {
    const aux = document.createElement('div');
    if(!html) return false
    aux.innerHTML = html; //parses the html
    const trimmedContent = aux.innerText.trim(); //get only the text and remove white space at the beginning and end
    return trimmedContent !== "";
  }

  const hasDetail = hasContent(story.details)
  const hasLongDescription = hasContent(story.longDescription)
  const hasButtons = (story.buttons && story.buttons.length > 0) || story.link || hasDetail

  const hasFold = hasLongDescription || (story.buttons && story.buttons.length > 0)

  const { isMobile } = useWindowResize()
  let navigate = useNavigate()
  const location = useLocation()


  const handleClickDetails = () => {
    navigate(`/story/${story.id}`, { state: { referrer: location.pathname }})
  }

  const handleNavigate = (link: string, target?: string, type?: string) => {
    if (type === "internal") {
      navigate(link)
    } else {
      window.open(link, target ?? "_blank")
    }
  }

  const longtext = useRef<HTMLDivElement>(null) // Gsap reference for animating "longtext"

  gsap.defaults({ ease: "power3.inOut", duration: 0.3 })

  useGSAP(() => {
    gsap.to(longtext.current,
      { height: 0, paddingTop: 0, paddingBottom: 0, opacity: 0 },
    )

    // Get parent element
    let parent = document.getElementById(`cm-` + story.id)

    if (foldState) {
      // Unfold
      parent?.classList.add(bgColor)
      gsap.to(longtext.current, { height: "auto", paddingBottom: "2rem", opacity: 1 })

    } else {
      // Fold
      gsap.to(longtext.current, { height: 0, paddingBottom: 0, opacity: 0 })
    }
  }, [foldState, bgColor, story.id])

  return (
    <div className={`
      ${!isMobile ? "py-8" : ""}
      ${(!hasFold && story.link) ? "cursor-pointer hover:scale-105 transition" : ""}
      
      `}>


      <div

      id={`cm-` + story.id}
      className={`
        block relative
        ${className ?? ""} 
        border-b border-hellgrau md:border-none
        ${isInvertColor ? "text-white" : "text-black"}
      `}>

        {
          story.effect === "confetti" &&
          <div className="pointer-events-none absolute inset-0 z-40">
            <css-doodle className="w-full h-full">{`
          :doodle {
            @grid: 30 / 100%;
            @gap: 1px;
          }
          @random(0.04) {
            background: @pick-n(#237073, #dfd800, #490f34, #64b949,#f08300 )
          }

          animation: spin 10s linear @rand(-5s, 0) infinite;

          @keyframes spin {
            from {
              transform: 
                rotate(0deg) 
                rotateY(0deg)
                translateY(-400px);
            }
            to { 
              transform: 
                rotate(@rand(360deg))
                rotateY(@rand(360deg))
                translateY(400px)
                skewX(@rand(20deg));
            }
          }
          `}</css-doodle>
          </div>
          
        }

      <div
            onClick={() => hasFold ? onFoldStateChanged && onFoldStateChanged(!foldState) : 
              (story.link && handleNavigate(story.link, story.linkTarget, story.linkType))
            }
        className={`
        ${story.horizontalFlip ? "md:flex-row-reverse" : "md:flex-row"} flex-col flex items-stretch items-center
        ${story.color ? BG_COLORS[story.color as keyof typeof BG_COLORS] : "bg-hellbeige"} 

        ${(hasFold) ? "cursor-pointer " : ""}


        relative
        min-h-56
        `}>
        {
          hasFold &&
          // Unfold icon
          <div className="absolute right-0 bottom-0 z-40">
            {
              (story.longDescription !== "" || story.link !== "" || (story.buttons && story.buttons.length > 0)) &&
              <UnfoldIcon onFoldStateChanged={onFoldStateChanged} unfold={foldState} />
            }
          </div>
        }

        {
          story.centered ?
          <div className="flex flex-col w-full justify-center items-center text-center p-4">
              <Title>{story.title}</Title>
              <div dangerouslySetInnerHTML={{ __html: story.shortDescription ?? "" }} />
          </div>
          :
          <>
          {
            // first column
            <div className="relative basis-1/2 flex justify-center items-center my-4">
              {
                // Photo
                story.photos && story.photos.length > 0 &&
                <div className="md:absolute z-30 flex justify-center items-center">
                  <img className={`h-64 w-auto object-cover`} src={story.photos[0].url} alt="placeholder" />
                </div>
              }
            </div>
          }
  
          {
            // second column
            <div className={`basis-1/2 ${isMobile ? "pt-4" :"pt-8"} px-8 pb-8 flex flex-col justify-center`}>
              <Title marginTop="0">{story.title}</Title>
              <div dangerouslySetInnerHTML={{ __html: story.shortDescription ?? "" }}>
              </div>
            </div>
          }
          </>
        }
      
      </div>

      {
        // Long text
        hasFold &&
        <div ref={longtext} className={`
          opacity-0 h-0 pt-0 pb-0 overflow-hidden
          ${story.color ? BG_COLORS[story.color as keyof typeof BG_COLORS] : "bg-hellbeige"} `}>
          {
            hasLongDescription &&
            <div className={`${isMobile ? "pt-4" : "pt-12"} px-8`} dangerouslySetInnerHTML={{ __html: story.longDescription ?? "" }}>
            </div>
          }

          {
            // Buttons
            hasButtons && <div className="flex justify-center space-x-4 pt-8">
              {
                // Details button
                hasDetail &&
                <Button onClick={handleClickDetails}>Details</Button>
              }

              {
                // Link button
                story.link && (
                  story.linkType === "internal" ?
                    <Button onClick={() => navigate(`/${story.link}`)}>Link</Button>
                    :
                    <Button onClick={() => window.open(story.link, story.linkTarget ?? "_blank")}>Link</Button>
                )
              }

              {
                story.buttons && story.buttons?.map(b => {
                  return <Button onClick={() => window.open(b.link, b.isExternal ? "_blank" : "_self")}>{b.text}</Button>
                })
              }
            </div>
          }
        </div>

      }
    </div>

    </div>
    
  )
}

export default ContentModule