import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

interface Props {
  value?: string,
  onEditorStateChange: (content: string) => void
}

const RichTextEditor = ({ value, onEditorStateChange }: Props) => {

  useEffect(() => {
    const Quill = ReactQuill.Quill

    const Link = Quill.import('formats/link');

    class MyLink extends Link {
      static create(value: any) {
        let node = Link.create(value);
        value = Link.sanitize(value);
        node.setAttribute('href', value);
        if (value.startsWith('https://') || value.startsWith('http://')) {
          node.className = 'link--external text-link'
        } else {
          node.removeAttribute('target');
          node.className = 'text-link'
        }

        return node;
      }

      format(name: any, value: any) {
        super.format(name, value);

        if (name !== this.statics.blotName || !value) {
          return;
        }

        if (value.startsWith('https://') || value.startsWith('http://')) {
          this.domNode.className = 'link--external text-link'
        } else {
          this.domNode.removeAttribute('target')
          this.domNode.className = 'text-link'

        }
      }
    }

    Quill.register(MyLink, true);
  }, [])

  const onChange = (value: string) => {
    onEditorStateChange(value)
  }

  return (
    <div className='bg-white'>
      <ReactQuill theme='snow' value={value} onChange={onChange} />
    </div>
  )
}

export default RichTextEditor