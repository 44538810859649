import React, {useState} from 'react';

type Props = {
    onChange: (pageNames: PageName[]) => void,
    pageNames?: PageName[]
}

function PageCheckboxes({onChange, pageNames}: Props) {

    const [selectedPageNames] = useState<Set<PageName>>(new Set(pageNames));
    const allPageNames: PageName[] = ["HOME", "BOOKS", "EVENTS", "PEOPLE", "ROOM", "DINGE"];

    const onCheck = (pageName: PageName) => {
        if (selectedPageNames.has(pageName)) {
            selectedPageNames.delete(pageName);
        } else {
            selectedPageNames.add(pageName);
        }
        onChange(Array.from(selectedPageNames));
    }

    return (
        <ul className="list-none items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            {
                allPageNames.map((pageName, index) => (
                    <li key={index}
                        className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                        <div className="flex items-center ps-3">
                            <input type="checkbox" value=""
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                   defaultChecked={selectedPageNames.has(pageName)}
                                   onChange={() => onCheck(pageName)}
                            />
                            <label htmlFor="vue-checkbox-list"
                                   className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                {pageName}
                            </label>
                        </div>
                    </li>
                ))
            }
        </ul>
    );
}

export default PageCheckboxes;
