import MenschenBackgroundImage from '../static/image/Menschen-low.jpg'
import ContentModule from '../components/ContentModule'
import Container from '../components/Container'
import usePageText from '../hooks/usePageText'
import useStories from '../hooks/useStories'
import useRandom from '../hooks/useRandom'
import Loader from '../components/Loader'
import useStoryFoldStates from '../hooks/useStoryFoldStates'

import MenschenStatic1 from '../static/image/Menschen-01-low.jpeg'
import Header from '../components/Header'
import useWindowResize from '../hooks/useWindowResize'

const Dinge = () => {

  const { pageText, loading: pageTextLoading } = usePageText("PEOPLE")
  const { stories, loading: storiesLoading } = useStories("PEOPLE")
  const { storyFoldStates, onModuleFoldStateChanged } = useStoryFoldStates(stories)

  const { seed } = useRandom(5)

  const { isMobile } = useWindowResize()

  return (
    <div>
      <Header bubbleText={"Menschen"} BgImage={MenschenBackgroundImage} />

      {
        // Page Text
        <Container>
          <div className={`${isMobile ? "mt-6" : "mt-16"}`}  dangerouslySetInnerHTML={{ __html: pageText }} />
        </Container>
      }

      {
        // STORIES
        <Container className='mt-6'  paddingMobile={false}>
          {
            stories.map((story) => {
              return (
                <ContentModule
                  foldState={storyFoldStates?.find(s => s.id === story.id)?.foldState}
                  onFoldStateChanged={(unfold) => onModuleFoldStateChanged(story.id, unfold)}
                  story={story} />)
            })
          }
        </Container>
      }

      {
        // Loader
        (pageTextLoading || storiesLoading) && <Loader seed={seed} />
      }



      {
        // STATIC IMAGE
        <Container paddingMobile={false}>
          <img alt="Menschen 1" src={MenschenStatic1} className={`w-full h-auto ${isMobile ? "mt-4" : "mb-24 mt-16 "}`} />
        </Container>
      }

    </div>

  )
}

export default Dinge