import {useEffect, useMemo, useState} from 'react';
import "./PageTexts.scss";
import {findPageTexts, updatePageTexts} from "../../../services/APIService";
import {useAuth} from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import PageLoader from "../../../components/admin/PageLoader";

import RichTextEditor from '../../../components/admin/RichTextEditor';

import {toast} from "react-toastify";

function PageTexts() {

    const [pageTexts, setPageTexts] = useState<PageTexts | null>();
    const [loading, setLoading] = useState<boolean>(true);

    const authContext = useAuth();
    const navigate = useNavigate();
    const serviceContext = useMemo(() => ({
        authContext,
        navigate
    }), [authContext, navigate]);

    const onContentChange = (pageName: string, content: string) => {
        setPageTexts({
            ...pageTexts,
            [pageName]: content
        })
    }

    const onSubmit = () => {
        setLoading(true);
        updatePageTexts(serviceContext, pageTexts).then(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        findPageTexts(serviceContext).then(pageTexts => {
            setPageTexts(pageTexts);
        }).catch((e) => {
            console.error(e);
            toast.error("Beim Laden des Inhalts ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.");
        }).finally(() => {
            setLoading(false);
        });
    }, [authContext, navigate, serviceContext]);

    const handleSetEditorState = (page: string, content: string) => {
        onContentChange(page, content)
    }

    return (

        <div className='dark:bg-gray-900'>
            <PageLoader visible={loading} />
            {
                !loading && (
                    <div className="pageContainer max-w-7xl mx-auto">
                        <div className="mb-5">
                            <label htmlFor="home" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Home
                            </label>
                            <textarea
                                id="home"
                                rows={4}
                                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={pageTexts?.HOME}
                                onChange={(e) => (onContentChange("HOME", e.target.value))}
                            >
                            </textarea>

                            <RichTextEditor value={pageTexts?.HOME} onEditorStateChange={(content) => handleSetEditorState("HOME", content)} />


                        </div>

                        <div className="mb-5">
                            <label htmlFor="books" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Books
                            </label>
                            <textarea
                                id="books"
                                rows={4}
                                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={pageTexts?.BOOKS}
                                onChange={(e) => (onContentChange("BOOKS", e.target.value))}
                            >
                            </textarea>

                            <RichTextEditor value={pageTexts?.BOOKS} onEditorStateChange={(content) => handleSetEditorState("BOOKS", content)} />

                        </div>

                        <div className="mb-5">
                            <label htmlFor="events"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Events
                            </label>
                            <textarea
                                id="events"
                                rows={4}
                                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={pageTexts?.EVENTS}
                                onChange={(e) => (onContentChange("EVENTS", e.target.value))}
                            >
                            </textarea>

                            <RichTextEditor value={pageTexts?.EVENTS} onEditorStateChange={(content) => handleSetEditorState("EVENTS", content)} />

                        </div>

                        <div className="mb-5">
                            <label htmlFor="people"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                People
                            </label>
                            <textarea
                                id="People"
                                rows={4}
                                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={pageTexts?.PEOPLE}
                                onChange={(e) => (onContentChange("PEOPLE", e.target.value))}
                            >
                            </textarea>

                            <RichTextEditor value={pageTexts?.PEOPLE} onEditorStateChange={(content) => handleSetEditorState("PEOPLE", content)} />

                        </div>

                        <div className="mb-5">
                            <label htmlFor="room" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Room
                            </label>
                            <textarea
                                id="room"
                                rows={4}
                                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={pageTexts?.ROOM}
                                onChange={(e) => (onContentChange("ROOM", e.target.value))}
                            >
                            </textarea>

                            <RichTextEditor value={pageTexts?.ROOM} onEditorStateChange={(content) => handleSetEditorState("ROOM", content)} />

                        </div>

                        <div className="mb-5">
                            <label htmlFor="dinge" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Dinge
                            </label>
                            <textarea
                                id="dinge"
                                rows={4}
                                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={pageTexts?.DINGE}
                                onChange={(e) => (onContentChange("DINGE", e.target.value))}
                            >
                            </textarea>

                            <RichTextEditor value={pageTexts?.DINGE} onEditorStateChange={(content) => handleSetEditorState("DINGE", content)} />

                        </div>

                        <button
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={onSubmit}
                        >
                            Save
                        </button>
                    </div>

                )
            }
        </div>
    )
}

export default PageTexts;
