type SocialMediaElementProps = {
  Element: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  link: string,
  alt: string
}

const SocialMediaElement = (props: SocialMediaElementProps) => {
  return (
    <a className="text-white hover:text-black duration-300 " href={props.link} target="_blank" rel="noreferrer">
      <props.Element className="w-8" />
    </a>
  )
}

export default SocialMediaElement