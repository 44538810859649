import EnvLoader from "../EnvLoader";

const H_X_API_KEY = "X_API_KEY";
const X_APP = "X_APP";

export const uploadImage = async (file: File) => {
    const env = EnvLoader.load();

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(`${env.tms.endpoint}/api/images/upload`, {
        method: 'POST',
        headers: {
            [H_X_API_KEY]: env.tms.apiKey,
            [X_APP]: env.tms.appName
        },
        body: formData
    })

    return await response.json() as ImageUploadResponse;
}