import { useForm } from "react-hook-form"
import useSendMail from "../hooks/useSendMail"
import Button from "./form/Button"
import Input from "./form/Input"
import React from "react"
import { htmlToText } from 'html-to-text';

import Moment from 'moment';
Moment.locale('de');

type Props = {
  story?: StoryToCreate
}
const ContactForm: React.FC<Props> = ({ story }) => {

  type FormData = {
    name: string,
    email: string,
    message: string
  }

  const { reset, register, handleSubmit, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      name: '',
      email: '',
      message: '',
    }
  })

  const { sendMail, loading, data, error } = useSendMail(() => {
    reset()
  })

  const onSubmit = (data: FormData) => {
    let content = `Nachricht erhalten von: ${data.name} 
    \nEmail addresse: ${data.email} 
    \n${story && `Anmeldung für: ${story.title} 
    \nBeschreibung: 
    \n${htmlToText(story?.shortDescription ?? "")} 
    \n${story.dateTimeStart && story.dateTimeEnd ? `Vom: ${Moment(story.dateTimeStart).format('DD. MMM, h:mm:ss')} bis ${Moment(story.dateTimeEnd).format('DD. MMM, h:mm:ss')} \n`
        : ""}
    `}
    \n Nachricht: ${data.message}
    `

    sendMail("Anmeldung", content)
  }

  return (
    <div>
      {
        data?.ok ?
          <div>
            <p>Vielen Dank für Ihre Nachricht</p>
            <p>Wir melden uns bei Ihnen!</p>
          </div>
          :
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input register={register("name", { required: "Bitte geben Sie Ihren Namen ein" })} placeholder="Ihr Name" errors={errors.name} />
              <Input register={register("email", {
                required: true, pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Bitte geben Sie eine gültige Email ein"
                },
              })} placeholder="Ihre Email" errors={errors.email} />
              <Input register={register("message", { required: "Bitte geben Sie eine Nachricht ein" })} placeholder="Ihre Nachricht" errors={errors.message} />
              <Button className="mt-4" type="submit" loading={loading}>Senden</Button>
            </form>

            {
              error && <p className="mt-4">Es ist ein Fehler mit der Übertragung der Nachricht aufgetreten. Bitte melden Sie sich direkt bei uns!</p>
            }
          </div>
      }
    </div>
  )
}

export default ContactForm