import Container from "../components/Container"
import Link from "../components/Link"

const Error404 = () => {
  return (
    <Container className="mt-8 text-center min-h-96 flex flex-col justify-center">
      <h1>404 - Seite nicht gefunden</h1>
      <p>Das tut uns jetzt wirklich leid. Wir konnten die gewünschte Seite nicht finden.</p>

      <Link to="/">Hier gehts zur Startseite</Link>

    </Container>
  )
}

export default Error404