import { useEffect, useState } from "react"
import config from "../config.json"

const usePageText = (pageName: string) => {
  const [pageText, setPageText] = useState("" as string)
  const [loading, setLoading] = useState(false)

  // Retrieve pageText from API
  useEffect(() => {
    setLoading(true)
    fetch(`${config.api.endpoint}/api/page-texts/fetch?page=${pageName}`).then((res) => {
      res.json().then((data) => {
        setPageText(data.result)
        setLoading(false)
      })
    }).catch(() => {
      console.error("There was an error fetching .")
      setLoading(false)
    })
  }, [setLoading, pageName])

  return {
    pageText,
    loading
  }
}

export default usePageText