export const initStory = (): StoryToCreate => ({

    id: 0,

    title: "",
    color: "hellbeige",

    shortDescription: "",
    longDescription: "",
    details: "",
    buttons: [],
    photos: [],
    pages: [],
    effect: "none",
    category: "none",

    horizontalFlip: false,
    isVisible: true,
    isArchived: false,
    centered: false,

    link: "",
    linkType: "external",
    linkTarget: "_blank"

})