import * as React from "react";
import {createContext, useContext} from "react";
import {useLocalStorage} from "usehooks-ts";
import {NavigateFunction} from "react-router/dist/lib/hooks";

type Props = {
    children: React.ReactNode
}

const INIT_ADMIN_CREDENTIAL: AdminCredential = {
    email: null,
    token: null,
}

const INIT_AUTH_CONTEXT_CONTENT: AuthContextContent = {
    adminCredential: null,
    login: () => {},
    logout: () => {}
}

const AuthContext = createContext<AuthContextContent>(INIT_AUTH_CONTEXT_CONTENT);

export const AuthProvider = ({ children }: Props) => {

    const [adminCredential, setAdminCredential] = useLocalStorage<AdminCredential>("ADMIN_CREDENTIAL", INIT_ADMIN_CREDENTIAL);

    const login = (data: AdminCredential, navigate: NavigateFunction) => {
        setAdminCredential(data);
        navigate("/admin", {
            replace: true
        })
    };

    const logout = (navigate: NavigateFunction) => {
        setAdminCredential(INIT_ADMIN_CREDENTIAL);
        navigate("/login", {
            replace: true
        });
    };

    return <AuthContext.Provider value={{
        adminCredential,
        login,
        logout
    }}>{children}</AuthContext.Provider>;

}

export const useAuth = () => {
    return useContext<AuthContextContent>(AuthContext);
};