import { PropsWithChildren } from "react"

type ParagraphProps = PropsWithChildren<{
  className?: string,
  marginTopClass?: string,
}>

const Paragraph : React.FC<ParagraphProps> = ({className, marginTopClass, children}) => {
  return (
    <p className={`
      ${className ?? ""}
      ${marginTopClass ?? "mt-4"}
      `}>
      {children}
    </p>
  )
}

export default Paragraph