import React, {useEffect, useRef, useState} from "react";
import {default as Datepicker} from "pickerjs";
import "pickerjs/dist/picker.min.css";
import moment from "moment";

type Props = {
    value?: Date,
    onChange?: (date?: Date) => void
}

function DateTimePicker({value, onChange}: Props) {

    const [dateDisplay, setDateDisplay] = useState<string | undefined>(undefined);
    const [date, setDate] = useState<Date | undefined>(undefined);
    const [instance, setInstance] = useState<any>(undefined);
    const input = useRef();

    const format = "MMM D, YYYY HH:mm";

    useEffect(() => {
        // @ts-ignore
        const tempInstance = new Datepicker(input.current, {
            date: dateDisplay,
            format,
            headers: true,
            rows: 3,
            controls: true,
            inline: true,
            text: {
                title: "Wählen Sie Datum und Uhrzeit",
            },
        });

        setInstance(tempInstance);

        // @ts-ignore
        input.current.addEventListener("change", event => {
            const dateDisplay = event.target.value;
            const date = moment(event.target.value, format).toDate();
            setDate(date);
            setDateDisplay(dateDisplay);
        });
    }, [dateDisplay]);

    useEffect(() => {
        if (instance && instance.format && !date && value !== date) {
            instance.setDate(value);
            setDate(value);
            setDateDisplay(moment(value).format(format));
        }
    }, [date, value, instance]);

    useEffect(() => {
        try {
            if (date) {
                if (value !== date) {
                    onChange && onChange(date);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [date, instance, value, onChange]);

    const onDelete = () => {
        // @ts-ignore
        input.current.value = "";
        setDate(undefined);
        setDateDisplay(undefined);
        onChange && onChange(undefined);
    }

    return (
        <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path fill="currentColor"
                          d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"></path>
                </svg>
            </div>
            {/* @ts-ignore */}
            <input value={dateDisplay} ref={input}
                   className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            <button
                className="text-white absolute end-2.5 bottom-2.5 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                onClick={onDelete}
            >X
            </button>
        </div>

    )
}

export default DateTimePicker;
